import React, { useEffect, useState } from "react";
import Axios from "axios";
import Popup from "reactjs-popup";
let CryptoJS = require("crypto-js");
import Popupone from "reactjs-popup";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";

import { CommonConstants } from "../../../_constants/common.constants";
import{GetUserDetails} from '../../../_helpers/Utility';

import Pagenations from "../../Pagenations";
import { history } from "../../../_helpers";

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../StickyHeader/StickyHeader";


const SortingIcons = ({SortedBy,sortedColumn,ColumnName}) => {
    return (
      <span className="shorting">
        <ArrowUpward className={SortedBy === 1 && sortedColumn === ColumnName? "active": null}/>
        <ArrowDownward className={SortedBy === -1 && sortedColumn === ColumnName? "active": null}/>
      </span>
    )
  }

export default function PhoneNumberTable() {


  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Flen, SetFlen] = useState(0);
  const [Sflag, SetSflag] = useState(false);
  const [Rlen, SetRlen] = useState(0);
  const [CountPage, SetCountPage] = useState(0);
  const [Rows, SetRows] = useState([]);
  const [Page, SetPage] = useState(1);
  const [RowsPerPage, SetRowsPerPage] = useState(100);
  const [Email, SetEmail] = useState("");
  const [InvitePopup, SetInvitePopup] = useState(false);
  const [RID, SetRID] = useState("");
  const [UserID, SetUserID] = useState(null);
  const [SortField, SetSortField] = React.useState("PhoneNumber");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [Alertbox, SetAlertbox] = useState(false);
  const [CUserID,SetCUserID] = React.useState(0)
  const [sortedColumn, setSortedColumn] = React.useState("PhoneNumber");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);


  useEffect(() => {
    let GetUserData = GetUserDetails();
    if (GetUserData != null) {
      SetClientID(GetUserData.ClientID);
      SetUserID(GetUserData.ParentUserID);
      SetCUserID(GetUserData.ChildUserID);
      SetRole(GetUserData.Role);
    }
    GetTwilioPhoneNumberList(GetUserData.ClientID,GetUserData.ParentUserID,GetUserData.Role);

  }, [Search, Page, RowsPerPage]);

  //Getting Twilio Phone number list
  const GetTwilioPhoneNumberList = (CID,UID,URole) => {
    try{
      SetIsLoading(true)
      let RequestBody = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Sort: true,
        Field: SortField,
        SortBy: SortedBy,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        Role: URole,
      };
      const ApiRespnose = Axios({
        url:CommonConstants.MOL_APIURL +  "/sdrassignment/GetTwilioPhoneNumber",
        method: "POST",
        data: RequestBody,
      });
      ApiRespnose.then((Result) => {
        
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
        setIsDataLoaded(true);
  
      });
    }catch(err){

    }
  }


 
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("Search").value?.trim();
      if(SearchedVal==Search){
        SetSearch(SearchedVal);
        SetPage(1);
      }else{
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if(NewPage==Page){
      SetPage(NewPage);
    }else{
        SetPage(NewPage);
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
    
  };

  //get sort field data

  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("Search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const AdminListAPI = Axios({
      url:CommonConstants.MOL_APIURL +"/sdrassignment/GetTwilioPhoneNumber",
      method: "POST",
      data: InputParameter,
    });
    AdminListAPI.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
    });
   
  };

  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a twilio phone number.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((Result) => {
      if (Result.isConfirmed) {
        let data = {
          ID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: CUserID,
          Role: Role,
        };
        Axios({
          url:CommonConstants.MOL_APIURL + "/sdrassignment/DeleteTwilioPhoneNumber",
          method: "POST",
          data: data,
        }).then((Res) => {
          if (Res) {
            if ((Res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Twilio phone number deleted successfully.",
                "success"
              );
              GetTwilioPhoneNumberList(ClientID,UserID,Role);
              SetPage(1)
              SetSearch("")
              document.getElementById("Search").value = "";
            } else {
            }
          }
        });
      } else if (Result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

// Add Button
  const AddBtn = () => {
    history.push("/addtwiliophonenumber");
  }

  // Edit Button
  const EditBtn = (ID) => {
    history.push({
      pathname: "/edittwiliophonenumber",
      state: { data: ID, Pagename: "" },
    });
  }

  return (
    <div>
       {
        IsLoading ? <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : <></>
      }
     
     <div className="row">
        <div className="col padd-0"></div>
        <div className="col padright flexdisplayer pr-3">
          <div className="fulleriger d-flex"> 
       
            <button
              onClick={AddBtn}
              type="submit"
              className="btn btn-primary btnColor m-btn m-btn--icon m-btn--air"
            >
              <span> 
                <span>Add</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row padt-25">
      
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />

<Table className="table-ref"  aria-label="collapsible table">     
         <TableHead>
              <TableRow>
                <TableCell onClick={() => { SortData("PhoneNumber"); setSortedColumn("PhoneNumber");}}>
                    Phone Numbers
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"PhoneNumber"}/>
                   
                </TableCell>
                <TableCell onClick={() => { SortData("Status"); setSortedColumn("Status");}}>
                    Status
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"Status"}/>
                </TableCell>
                <TableCell onClick={() => { SortData("RedirectNumber"); setSortedColumn("RedirectNumber");}}>
                    Redirect Mobile
                    <SortingIcons SortedBy={SortedBy} sortedColumn={sortedColumn} ColumnName={"RedirectNumber"}/>
                </TableCell>
                <TableCell >Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? <p className="text-center">No data in Available</p> :
               Rows?.map((row) => (
                <TableRow key= {row?._id}>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{row.RedirectNumber}</TableCell>
                  <TableCell>
                  <a
                        title="Edit"
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                    <a
                      onClick={() => { DeleteBtn(row?._id)}}
                      className="btn-eyesicon"
                      title="Delete"
                    >
                      <i className="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagenations
         Sflag={Sflag}
         CountPage={CountPage}
         HandleChangePage={HandleChangePage}
         Rlen={Rlen}
         Page={Page}
         RowsPerPage={RowsPerPage}
         Flen={Flen}
      />
    </div>
  );
}
