import React from "react";
import Axios from "axios";
import Popupone from "reactjs-popup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import SDRAssignmentsTabing from "../../_components/user/SDRAssignments/SDRAssignmentsTabing";
import Footer from "../../_components/user/footer/footer";

class SDRAssignmentPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "SDR Assignments | SalesHive"
    }


    render() {

        return (
            <>
                <div className="adminmain mheight">
                    <Sidebar className="" />
                    <div className="bodyhome">
                        <div className="row">
                            <div className="col">
                                <h4 className="headertitlebd lg-headertitle ">SDR Assignments</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <SDRAssignmentsTabing />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
};

const connectedSDRAssignmentPage = connect(mapState, actionCreators)(SDRAssignmentPage);
export { connectedSDRAssignmentPage as SDRAssignmentPage };
