import React, { useEffect,useRef } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import ReactApexchart from "react-apexcharts";
import $ from "jquery";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";


import CollapsibleTable from "../bookedmeeting/usertable";
import InsightsTable from "../bookedmeeting/InsightsTabing";
import Pipeline from "../bookedmeeting/Pipeline";
import MeetingSource from "../bookedmeeting/MeetingSource";

import loadingi from "../../../images/loading.gif";
import { GetUserDetails } from "../../../_helpers/Utility";
import { GetClientDetails } from "../../../_helpers/Utility";
const Fileupload = require("../../../_helpers/fileupload");
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { AddBookedMeetingPage } from "../../../user/BookedMeetingsPage/AddBookedMeetingPage/AddBookedMeetingPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

charts(FusionCharts);

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [reset, setReset] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [csvdata, setCsvdata] = React.useState([]);
  const [DropBoxData, SetDropBoxData] = React.useState([]);
  const [opencsv, setOpencsv] = React.useState(false);
  const [MapCsvs, SetMapcsvs] = React.useState(false);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [exportdta, setexportdta] = React.useState([]);
  const [Won, setWon] = React.useState("");
  const [totalcount, setTotal] = React.useState("");
  const [lost, setLost] = React.useState("");
  const [percent, setPercentage] = React.useState("");
  const [chartdata, setChartdata] = React.useState({});
  const [chartsourcedata, setChartsourcedata] = React.useState({});
  const [filterdata1, setfilterdata1] = React.useState([]);
  const [data, setdata] = React.useState([]);
  const [filterpropertdata32, setfilterpropertdata32] = React.useState([]);
  const [defaulselectfilter, setdefaulselectfilter] = React.useState("");
  const [Counter, setCounter] = React.useState([1]);
  const [Counter2, setCounter2] = React.useState([1]);
  const [rlen, setrlen] = React.useState(0);
  const [flen, setflen] = React.useState(0);
  const [countpage, setcountpage] = React.useState(0);
  const [Cname, SetCname] = React.useState("");
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [page, setpage] = React.useState(1);
  const [rows2, setrows2] = React.useState([]);
  const [selectedvalue, setselectedvalue] = React.useState("");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [bfdata, setbfdata] = React.useState([]);
  const [TotalResult, SetTotalResult] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalSuccess, SetTotalSuccess] = React.useState(0);
  const [TotalSkiped, SetTotalSkiped] = React.useState(0);
  const [Sum, SetSum] = React.useState(0);
  const [TotalDisplayCount, SetTotalDisplayCount] = React.useState(false);
  const [TotalDisplayCsvError, SetTotalDisplayCsvError] = React.useState(false);
  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [FieldAttributes, SetFieldAttributes] = React.useState([
    {
      id: 3242342323,
      ColumnName: "AccountName",
      InputType: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
    },
  ]);
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [IsApply, SetIsApply] = React.useState(false);
  const [FilterName, SetFilterName] = React.useState(null);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [DeletedFilterProperty, SetDeletedFilterProperty] = React.useState([]);
  const [Role, setRole] = React.useState("");
  const [exportdtalength, setexportdtalength] = React.useState(0);

  const [FirstName, SetFirstName] = React.useState(null);
  const [LastName, SetLastName] = React.useState(null);
  const [Email, SetEmail] = React.useState(null);
  const [Title, SetTitle] = React.useState(null);
  const [Company, SetCompany] = React.useState(null);
  const [CompanyPhone, SetCompanyPhone] = React.useState(null);
  const [Website, SetWebsite] = React.useState(null);
  const [LinkedInURL, SetLinkedInURL] = React.useState(null);
  const [Industry, SetIndustry] = React.useState(null);
  const [DirectPhone, SetDirectPhone] = React.useState(null);
  const [MeetingSources, SetMeetingSources] = React.useState(null);
  const [MeetingOwner, SetMeetingOwner] = React.useState(null);
  const [MeetingSetDate, SetMeetingSetDate] = React.useState(null);
  const [ReasonUnqualified, SetReasonUnqualified] = React.useState(null);
  const [MeetingStatus, SetMeetingStatus] = React.useState(null);
  const [ClientPOCID, SetClientPOCID] = React.useState(null);
  const [MeetingTimeZone, SetMeetingTimeZone] = React.useState(null);
  const [ContractValue, SetContractValue] = React.useState(null);
  const [MeetingSetBy, SetMeetingSetBy] = React.useState(null);
  const [MeetingDate, SetMeetingDate] = React.useState(null);
  const [SendReminder, SetSendReminder] = React.useState(null);
  const [NoteTitle, SetNoteTitle] = React.useState(null);
  const [Notes, SetNotes] = React.useState(null);
  const [MapAttrBox, SetMapAttrBox] = React.useState(false);
  const [UpdateFlag, SetUpdateFlag] = React.useState(false);
  const [UpdateImportList, SetUpdateImportList] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0);
  const [DisabledAddFilter,SetDisabledAddFilter] = React.useState(false)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [SampleExportData, SetSampleExportData] = React.useState([]);
  const [ExportDisplayData, SetExportDisplayData] = React.useState([]);
    // csv errors table details
    const [Data, SetData] = React.useState([]);
    const [Flen, SetFlen] = React.useState(0);
    const [Sflag, SetSflag] = React.useState(false);
    const [Rlen, SetRlen] = React.useState(0);
    const [CountPage, SetCountPage] = React.useState(0);
    const [CSVEPage, SetCSVEPage] = React.useState(1);
    const [CSVERowsPerPage, SetCSVERowsPerPage] = React.useState(15);
    const [Page, SetPage] = React.useState(1);
    const [Search, SetSearch] = React.useState("");
    const [SortField, SetSortField] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [MeetingCustomFieldData, setMeetingCustomFieldData] = React.useState(
      []
    );
    //For Filter

    const [OpenAddNewFilter,SetOpenAddNewFilter] = React.useState(false)
    const [OpenEditFilter,SetOpenEditFilter] = React.useState(false)

    const [TotalGoal, SetTotalGoal] = React.useState([]);
    const [TotalEvent, SetTotalEvent] = React.useState([]);
    const [TotalGoalSum, SetTotalGoalSum] = React.useState(0);
    const [TotalEventSum, SetTotalEventSum] = React.useState(0);
    const [TotalWeekDay, SetTotalWeekDay] = React.useState([]);
    const [TotalCrush, SetTotalCrush] = React.useState(0);
    const [ContactimportlimitaionMessage, SetContactimportlimitaionMessage] =
    React.useState("");

  useEffect(() => {
    document.title = `Booked Meetings | SalesHive`;
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      setClientID(getaccountcatelist.ClientID);
      setUserID(getaccountcatelist.ParentUserID);
      SetCUserID(getaccountcatelist.ChildUserID);
      setRole(getaccountcatelist.Role);
    }
    GetClientDetails(getaccountcatelist.ClientID).then((result) => {
      SetCname(result[0]?.Name);
    });
    GetCountdetails(getaccountcatelist.ClientID, getaccountcatelist.Role);
    GetChartdetails(getaccountcatelist.ClientID, getaccountcatelist.Role);
    GetChartsourcedetails(getaccountcatelist.ClientID, getaccountcatelist.Role);
    //Exportdetails(getaccountcatelist.ClientID, getaccountcatelist.Role);
    Getfilterlist(getaccountcatelist.ClientID, getaccountcatelist.Role);
    GetCustomFiledsData(getaccountcatelist.ClientID, getaccountcatelist.Role);
    SampleFileExportdetails()
    GetCustomfielddetails(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID, getaccountcatelist.Role);
    MeetingKeyMatricGet(
      getaccountcatelist.ClientID,
      getaccountcatelist.ParentUserID,
      getaccountcatelist.Role
    );
    document.getElementById("hidelod").style.display = "none";
    // Getfilterpropertylist();
  }, [FilterParameters]);

  //get count meeting
  const GetCountdetails = (CID, URole) => {
    var str_in = {
      ClientID: CID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetTotalClosedCount",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setTotal(result.data?.Totalcount);
      setWon(result.data?.TotalWoncount);
      setLost(result.data?.TotalLostcount);
      setPercentage(result.data?.ClosedWonrate);
    });
  };

  const MeetingKeyMatricGet = (CID, UID, role) => {
    // SetIsLoading(true)
    const InputParameters = {
      ClientID: CID,
      UserID: UID,
      Role: role,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
        //  var lstTotalGoal = [];
          var lstTotalEvent = [];
          var lstTotalWeekDay = [];
         // var objTotalGoalSum = 0;
          var objTotalEventSum = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
          //  var Goal = Res.data.Data[i].TotalGoal;
            var Event = Res.data.Data[i].TotalEvent;
            var WeekDay = Res.data.Data[i].WeekDay;
           // var GraphGoal = Res.data.Data[i].GraphTotalGoal;
            var GraphEvent = Res.data.Data[i].GraphTotalMeeting;
            // lstTotalGoal.push(GraphGoal);
            // objTotalGoalSum = objTotalGoalSum + Goal;

            lstTotalEvent.push(GraphEvent);
            objTotalEventSum = objTotalEventSum + Event;

            lstTotalWeekDay.push(WeekDay);
          }
        //  SetTotalGoal(lstTotalGoal);
          SetTotalEvent(lstTotalEvent);
         // SetTotalGoalSum(objTotalGoalSum);
          SetTotalEventSum(objTotalEventSum);
          SetTotalWeekDay(lstTotalWeekDay);
          // SetTotalCrush(
          //   objTotalEventSum > 0 && objTotalGoalSum > 0
          //     ? Math.round((objTotalEventSum * 100) / objTotalGoalSum)
          //     : 0
          // );
          // SetIsLoading(false)
        } else {
        //  SetTotalGoal([]);
          SetTotalEvent([]);
         // SetTotalGoalSum(0);
          SetTotalEventSum(0);
          SetTotalWeekDay([]);
          //SetIsLoading(false)
        }
      } else {
       // SetTotalGoal([]);
        SetTotalEvent([]);
       // SetTotalGoalSum(0);
        SetTotalEventSum(0);
        SetTotalWeekDay([]);
        // SetIsLoading(false)
      }
    });
  };

  const state = {
    series: [
      // {
      //   name: "Goal",
      //   color: "#360947",
      //   data: TotalGoal,
      // },
      {
        name: "Meetings",
        color: "#F8BF58",
        data: TotalEvent,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,  
        } 
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: TotalWeekDay,
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "bottom",
        offsetX: -10,
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };
  


    //get count meeting
    const GetCustomfielddetails = (CID,UID, URole) => {
      var str_in = {
        ClientID: CID,
        UserID: UID,
        Role: URole,
      };
      const rows1 = Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/MeetingCustomFieldForMeeting",
        method: "POST",
        data: str_in,
      });
      rows1.then((result) => {
        setMeetingCustomFieldData(result.data.meetingCustomField)
      });
    };

  //get count meeting status
  const GetChartdetails = (CID, URole) => {
    var str_in = {
      ClientID: CID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetChartStatus",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      const dataSource = {
        chart: {
          //caption: "Meeting Status",
          theme: "fusion", 
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          // showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%", 
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom", 
          legendBgColor: "#fff", 
          legendBorderColor: "#fff",
          legendShadow: 0,
          legendItemFontSize: "13",   
          legendNumColumns: "2",  
          plothighlighteffect: "fadeout|color=#111C43, alpha=100", 
          legendScrollBgColor: "#fff",
          minimiseWrappingInLegend: "1",
          alignLegendWithCanvas: "1",
          legendNumRows: 3
        },
        data: result.data?.data,
      };
      setChartdata(dataSource);
      SetExportDisplayData(result.data?.data)
    });
  };

  //get count meeting source
  const GetChartsourcedetails = (CID, URole) => {
    var str_in = {
      ClientID: CID,
      Role: URole,
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetChartSource",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      const dataSource1 = {
        chart: {
          //caption: "Meeting Source",
          /*start design round chart*/
          theme: "fusion", 
          subCaption: " ",
          formatnumberscale: "0",
          showLegend: "1",
          showLabels: "0",
          // showValues: "1",
          valuePosition: "inside",
          numbersuffix: "%", 
          showPercentValues: "0",
          showPercentInToolTip: "0",
          enableSlicing: "1",
          legendPosition: "bottom", 
          legendBgColor: "#fff", 
          legendBorderColor: "#fff",
          legendShadow: 0,
          legendItemFontSize: "13",   
          legendNumColumns: "2",  
          plothighlighteffect: "fadeout|color=#111C43, alpha=100", 
          legendScrollBgColor: "#fff",
          minimiseWrappingInLegend: "1",
          alignLegendWithCanvas: "1",
          legendNumRows: 3
        },
        data: result.data?.data,
      };
      setChartsourcedata(dataSource1);
    });
  };

  //Export details
  const Exportdetails = async(CID, URole) => {
    

      var str_in = {
        ClientID: CID,
        Role: URole,
        IsApply: FilterParameters?.length ===0?false:true,
        BookMeetingFilterArray: FilterParameters,
      };

    const rows1 = await Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/ExportBookedMeeting",
      method: "POST",
      data: str_in,
    });
    if(rows1.data.StatusMessage === "SUCCESS"){
      return rows1.data;
    };
  };

  const SampleFileExportdetails = () => {
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/GetSampleMeetingExport",
      method: "POST",
    }).then((res) => {
      SetSampleExportData(res?.data);
    });
  };

  const exportcsv = async() => {
    try {
     // SetIsloadingEss(true)
     document.getElementById("hidelod").style.display = "block";
      const result = await Exportdetails(ClientID,Role);
      if (result.StatusMessage === "SUCCESS") {
        const data = result.Data;
        if (data.length > 0) {
          setexportdta(data);
          const downloader = document.createElement("a");
          const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));
          downloader.setAttribute("href", csvContent);
          downloader.setAttribute("download", result.ClientName.Name+"-Meeting.csv");
          downloader.click();
          toast.success(<div>Meeting <br/>Data exported successfully.</div>);
         // SetIsloadingEss(false)
         document.getElementById("hidelod").style.display = "none";
        } else {
          toast.error("No data available for export.");
        //  SetIsloadingEss(false)
        document.getElementById("hidelod").style.display = "none";
        }
      } else {
        toast.error("Failed to export data. Please try again.");
      //  SetIsloadingEss(false)
      document.getElementById("hidelod").style.display = "none";
      }
    } catch (error) {
      console.error("ExportCsv error:", error);
      toast.error("An error occurred while exporting data.");
    }
  };

  
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach(item => {
        const values = headers.map(header => {
            // Check if the value is a string before attempting to replace characters
            const value = typeof item[header] === 'string' ? item[header] : String(item[header]);
            const escapedValue = value.replace(/"/g, '""'); // Escape double quotes
            return `"${escapedValue}"`; // Enclose value in double quotes
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
}

  
  charts(FusionCharts);

    // This function will be passed as a prop to CollapsibleTable
    const updateFromChild = (value) => {
      SetUpdateFlag(value);
      Exportdetails(ClientID, Role)
      GetCountdetails(ClientID, Role) // Set the flag to true or false as needed
    };
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnError = (err) => {};

  const handleOnRemoveFile = (data) => {};

  const resetcsv = () => {
    setReset(true);
  };

  //handle
  // const handleOnDrop = (data, fileInfo) => {
  //   var filename = fileInfo.name;
  //   setFiles(fileInfo);
  //   var sep = filename.split(".");
  //   if (sep[1] != "csv") {
  //     setReset(true);
  //     resetcsv();
  //   } else {
  //     var csvfile = sep[0] + new Date().getTime() + "." + sep[1];
  //     setFilename(csvfile);
  //     setCsvdata(data);
  //     SetDropBoxData(data[0].data);
  //   }
  // };

  const handleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;
    setFiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1] !== "csv") {
      setReset(true);
      resetcsv();
    } else {
      var csvfile = sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      setFilename(csvfile);
  
      // Filter out rows with all empty strings
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''));
  
      // Set the filtered data to csvdata
      setCsvdata(filteredData);
      SetDropBoxData(filteredData[0].data);
    }
  };
  

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  //import
  const importcsv = (e) => {
    e.preventDefault();
    setOpencsv(true);
  };

  // map csv
  const AccountMapCsv = () => {
    if (csvdata.length != 0) {
      DropBoxData.map((dddd, index) => {});
      SetMapAttrBox(false);
      document.getElementById("map_attribute").style.display = "block";
      setReset(true);
    } else {
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Please select file
        </div>
      );
      setReset(true);
     SetMapAttrBox(true);
    }
    hidesCSVHeaderOptions();
  };

  const hidesCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "FirstName") {
      SetFirstName(value);
    } else if (Name == "LastName") {
      SetLastName(value);
    } else if (Name == "Email") {
      SetEmail(value);
    } else if (Name == "Title") {
      SetTitle(value);
    } else if (Name == "Company") {
      SetCompany(value);
    } else if (Name == "Website") {
      SetWebsite(value);
    } else if (Name == "CompanyPhone") {
      SetCompanyPhone(value);
    } else if (Name == "DirectPhone") {
      SetDirectPhone(value);
    } else if (Name == "LinkedInURL") {
      SetLinkedInURL(value);
    } else if (Name == "Industry") {
      SetIndustry(value);
    } else if (Name == "MeetingSetBy") {
      SetMeetingSetBy(value);
    } else if (Name == "SendReminder") {
      SetSendReminder(value);
    } else if (Name == "MeetingOwnerID") {
      SetMeetingOwner(value);
    } else if (Name == "NoteTitle") {
      SetNoteTitle(value);
    } else if (Name == "MeetingStatus") {
      SetMeetingStatus(value);
    } else if (Name == "ClientPOCID") {
      SetClientPOCID(value);
    } else if (Name == "MeetingSetDate") {
      SetMeetingSetDate(value);
    } else if (Name == "MeetingTimeZone") {
      SetMeetingTimeZone(value);
    } else if (Name == "ContractValue") {
      SetContractValue(value);
    } else if (Name == "MeetingDate") {
      SetMeetingDate(value);
    } else if (Name == "Notes") {
      SetNotes(value);
    } else if (Name == "MeetingSources") {
      SetMeetingSources(value);
    }

    hidesCSVHeaderOptions()
  });

  //mapcsv
  const Savecsv = async() => {
    try {
    var OriginalFileName = files.name;
    var onlyname = OriginalFileName.substring(
      0,
      OriginalFileName.lastIndexOf(".csv")
    );

   // var UserEmail = document.getElementById("UserEmail").value;
    var saveAllContacts = [];
    var FirstName = parseInt(document.getElementById("FirstName")?.value);
    if (FirstName >= 0) {
      var ObjFirstName = {
        FieldAlias: "First Name",
        Fieldname: "FirstName",
        Customfield: null,
        index: FirstName,
      };
      saveAllContacts.push(ObjFirstName);
    }
    var LastName = parseInt(document.getElementById("LastName")?.value);
    if (LastName >= 0) {
      var ObjLastName = {
        FieldAlias: "Last Name",
        Fieldname: "LastName",
        Customfield: null,
        index: LastName,
      };
      saveAllContacts.push(ObjLastName);
    }
    var Email = parseInt(document.getElementById("Email")?.value);
    if (Email >= 0) {
      var ObjEmail = {
        FieldAlias: "Email",
        Fieldname: "Email",
        Customfield: null,
        index: Email,
      };
      saveAllContacts.push(ObjEmail);
    }
    var Title = parseInt(document.getElementById("Title")?.value);
    if (Title >= 0) {
      var ObjTitle = {
        FieldAlias: "Title",
        Fieldname: "Title",
        Customfield: null,
        index: Title,
      };
      saveAllContacts.push(ObjTitle);
    }
    var Company = parseInt(document.getElementById("Company")?.value);
    if (Company >= 0) {
      var ObjCompany = {
        FieldAlias: "Company",
        Fieldname: "Company",
        Customfield: null,
        index: Company,
      };
      saveAllContacts.push(ObjCompany);
    }

    var Website = parseInt(document.getElementById("Website")?.value);
    if (Website >= 0) {
      var ObjWebsite = {
        FieldAlias: "Website",
        Fieldname: "Website",
        Customfield: null,
        index: Website,
      };
      saveAllContacts.push(ObjWebsite);
    }
    var CompanyPhone = parseInt(document.getElementById("CompanyPhone")?.value);
    if (CompanyPhone >= 0) {
      var ObjCompanyPhone = {
        FieldAlias: "Company Phone",
        Fieldname: "CompanyPhone",
        Customfield: null,
        index: CompanyPhone,
      };
      saveAllContacts.push(ObjCompanyPhone);
    }
    var DirectPhone = parseInt(document.getElementById("DirectPhone")?.value);
    if (DirectPhone >= 0) {
      var ObjDirectPhone = {
        FieldAlias: "Direct Phone",
        Fieldname: "DirectPhone",
        Customfield: null,
        index: DirectPhone,
      };
      saveAllContacts.push(ObjDirectPhone);
    }
    var DirectPhoneExt = parseInt(document.getElementById("DirectPhoneExt")?.value);
    if (DirectPhoneExt >= 0) {
      var ObjDirectPhoneExt = {
        FieldAlias: "Direct Phone Ext",
        Fieldname: "DirectPhoneExt",
        Customfield: null,
        index: DirectPhoneExt,
      };
      saveAllContacts.push(ObjDirectPhoneExt);
    }
   // DirectPhoneExt
    var LinkedInURL = parseInt(document.getElementById("LinkedInURL")?.value);
    if (LinkedInURL >= 0) {
      var ObjLinkedInURL = {
        FieldAlias: "LinkedIn URL",
        Fieldname: "LinkedInURL",
        Customfield: null,
        index: LinkedInURL,
      };
      saveAllContacts.push(ObjLinkedInURL);
    }
    var Industry = parseInt(document.getElementById("Industry")?.value);
    if (Industry >= 0) {
      var ObjIndustry = {
        FieldAlias: "Industry",
        Fieldname: "Industry",
        Customfield: null,
        index: Industry,
      };
      saveAllContacts.push(ObjIndustry);
    }

    var MeetingSetBy = parseInt(document.getElementById("MeetingSetBy")?.value);
    if (MeetingSetBy >= 0) {
      var ObjMeetingSetBy = {
        FieldAlias: "Meeting Set By",
        Fieldname: "MeetingSetBy",
        Customfield: null,
        index: MeetingSetBy,
      };
      saveAllContacts.push(ObjMeetingSetBy);
    }
    var SendReminders = parseInt(document.getElementById("SendReminder")?.value);
    if (SendReminders >= 0) {
      var ObjSendReminders = {
        FieldAlias: "Send Reminder",
        Fieldname: "SendReminder",
        Customfield: null,
        index: SendReminders,
      };
      saveAllContacts.push(ObjSendReminders);
    }

    var MeetingOwnerID = parseInt(
      document.getElementById("MeetingOwnerID")?.value
    );
    if (MeetingOwnerID >= 0) {
      var ObjMeetingOwnerID = {
        FieldAlias: "Meeting Owner",
        Fieldname: "MeetingOwner",
        Customfield: null,
        index: MeetingOwnerID,
      };
      saveAllContacts.push(ObjMeetingOwnerID);
    }
    var Notess = document.getElementById("Notes")?.value;
    if (Notess != "") {
      var ObjNotess = {
        FieldAlias: "Notes",
        Fieldname: "Notes",
        Customfield: null,
        index: Notess,
      };
      saveAllContacts.push(ObjNotess);
    }

    var NoteTitles = parseInt(document.getElementById("NoteTitle")?.value);
    if (NoteTitles >= 0) {
      var ObjNoteTitles = {
        FieldAlias: "Note Title",
        Fieldname: "NoteTitle",
        Customfield: null,
        index: NoteTitles,
      };
      saveAllContacts.push(ObjNoteTitles);
    }
    var SetMeetingStatues = parseInt(
      document.getElementById("MeetingStatus")?.value
    );
    if (SetMeetingStatues >= 0) {
      var ObjSetMeetingStatues = {
        FieldAlias: "Meeting Status",
        Fieldname: "MeetingStatus",
        Customfield: null,
        index: SetMeetingStatues,
      };
      saveAllContacts.push(ObjSetMeetingStatues);
    }
    var ClientPOCID = parseInt(document.getElementById("ClientPOCID")?.value);
    if (ClientPOCID >= 0) {
      var ObjClientPOCID = {
        FieldAlias: "Client POC",
        Fieldname: "ClientPOC",
        Customfield: null,
        index: ClientPOCID,
      };
      saveAllContacts.push(ObjClientPOCID);
    }
    var MeetingSetDate = parseInt(
      document.getElementById("MeetingSetDate")?.value
    );
    if (MeetingSetDate >= 0) {
      var ObjMeetingSetDate = {
        FieldAlias: "Meeting Set Date",
        Fieldname: "MeetingSetDate",
        Customfield: null,
        index: MeetingSetDate,
      };
      saveAllContacts.push(ObjMeetingSetDate);
    }
    var MeetingTimeZone = parseInt(
      document.getElementById("MeetingTimeZone")?.value
    );
    if (MeetingTimeZone >= 0) {
      var ObjMeetingTimeZone = {
        FieldAlias: "Meeting Time Zone",
        Fieldname: "MeetingTimeZone",
        Customfield: null,
        index: MeetingTimeZone,
      };
      saveAllContacts.push(ObjMeetingTimeZone);
    }
    var ContractValue = parseInt(
      document.getElementById("ContractValue")?.value
    );
    if (ContractValue >= 0) {
      var ObjContractValue = {
        FieldAlias: "Contract Value",
        Fieldname: "ContractValue",
        Customfield: null,
        index: ContractValue,
      };
      saveAllContacts.push(ObjContractValue);
    }
    var MeetingDate = parseInt(document.getElementById("MeetingDate")?.value);
    if (MeetingDate >= 0) {
      var ObjMeetingDate = {
        FieldAlias: "Meeting Date",
        Fieldname: "MeetingDate",
        Customfield: null,
        index: MeetingDate,
      };
      saveAllContacts.push(ObjMeetingDate);
    }

    var MeetingSources = parseInt(
      document.getElementById("MeetingSources")?.value
    );
    if (MeetingSources >= 0) {
      var ObjMeetingSources = {
        FieldAlias: "Meeting Source",
        Fieldname: "MeetingSource",
        Customfield: null,
        index: MeetingSources,
      };
      saveAllContacts.push(ObjMeetingSources);
    }

    {
      MeetingCustomFieldData?.map((customfieldvalue) =>{
        var CustomFieldValuesIndex = parseInt(document.getElementById(customfieldvalue?.FieldName).value);
        if (CustomFieldValuesIndex >= 0) {
          var ObjCustomFieldValuesIndex = {
            FieldAlias: customfieldvalue?.FieldName,
            Fieldname: customfieldvalue?.FieldName.split(" ").join(""),
            Customfield: true,
            index: CustomFieldValuesIndex,
          };
          saveAllContacts.push(ObjCustomFieldValuesIndex);
        }
      })
    }

    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;
    var Title = document.getElementById("Title").value;
    var Company = document.getElementById("Company").value;
    var UserEmail = document.getElementById("Email").value;
    var LinkedInURL = document.getElementById("LinkedInURL").value;
    var MeetingStatus = document.getElementById("MeetingStatus").value;
    var MeetingSource = document.getElementById("MeetingSources").value;
    var MeetingSetDate = document.getElementById("MeetingSetDate").value;
    var MeetingTimeZone = document.getElementById("MeetingTimeZone").value;
    var DirectPhone = document.getElementById("DirectPhone").value;
    var CompanyPhone = document.getElementById("CompanyPhone").value;
    var MeetingSetBy = document.getElementById("MeetingSetBy").value;
    var MeetingDate =
    document.getElementById("MeetingDate").value;
    var SendReminder = document.getElementById("SendReminder").value;
    var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
    var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
    var ContractValue = document.getElementById("ContractValue").value;
    var Industry = document.getElementById("Industry").value;
    // var MeetingSetbyID = document.getElementById("MeetingSetbyID").value;
    var Website = document.getElementById("Website").value;
    var NoteTitle = document.getElementById("NoteTitle").value;
    var Notes = document.getElementById("Notes").value
    var IsUpdateExistContactss = document.getElementById(
      "IsUpdateExistContacts"
    ).checked;
    // if (IsUpdateExistContactss == false) {
    //   IsUpdateExistContactss = true;
    // } else {
    //   IsUpdateExistContactss = false;
    // }

    

    if (FirstName == "") {
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          First Name is required!
        </div>
      );

    }else if(LastName==""){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Last Name is required!
        </div>
      );
    }else if(UserEmail==""){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Email is required!
        </div>
      );
    }else if(MeetingSource==""){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Meeting Source is required!
        </div>
      );
    }else if(MeetingOwnerID==""){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Meeting Owner is required!
        </div>
      );
    }else if(MeetingDate==""){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Meeting Date is required!
        </div>
      );
    }else if(csvdata.length<=1){
      toast.error(
        <div className="toastsize">
          Meetings
          <br />
          Data not exists, Please try again!
        </div>
      );
    } else {
      document.getElementById("hidelod").style.display = "block";
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];
        var myresult = {
          fields: "Coverimg",
          path: "./Content/UploadedFiles/UserBookedMeeting",
          file: _base64,
          imageName: filename,
          FileData: csvdata,
          Email: UserEmail,
          ClientID: ClientID,
          UserID: UserID,
          CreatedBy: CUserID,
          CreatedDate: new Date(),
          saveAllContacts: saveAllContacts,
          IsUpdateExistContacts: IsUpdateExistContactss,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/bookedmeeting/ImportCSVMeetingData",
          method: "POST",
          data: myresult,
        }).then((Res) => {
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">Meeting added successfully.</div>
            );

            setOpencsv(true);
            SetMapAttrBox(true);
            document.getElementById("map_attribute").style.display = "block";
            SetTotalResult(Res.data.Total);
            SetTotalDuplicate(Res.data.TotalDuplication);
            SetTotalError(Res.data.TotalError);
            SetTotalSuccess(Res.data.TotalSuccess);
            SetTotalSkiped(Res.data.TotalSkip)
            SetContactimportlimitaionMessage(
              Res.data.LimitationimportMessage
            );
            SetTotalDisplayCount(true);
            SetTotalDisplayCsvError(true)
            updateFromChild(true)
            setCsvdata([]);
            SetUpdateImportList(Res.data.TotalDetailsData)
           
            SetData(Res.data.TotalDetailsData);
            SetRlen(Res.data.TotalDetailsData.length);
            SetFlen(Res.data.TotalDetailsData.length);
            const totalPages = Math.ceil(
              Res.data.TotalDetailsData.length / CSVERowsPerPage
            );
            SetCountPage(totalPages);

            document.getElementById("hidelod").style.display = "none";
          } else {
            toast.error(Res.data.Message);
            setOpencsv(true);
            SetMapAttrBox(true);
            document.getElementById("map_attribute").style.display = "block";
            SetTotalResult(Res.data.Total);
            SetTotalDuplicate(Res.data.TotalDuplication);
            SetTotalError(Res.data.TotalError);
            SetTotalSuccess(Res.data.TotalSuccess);
            SetTotalSkiped(Res.data.TotalSkip)
            SetContactimportlimitaionMessage(
              Res.data.LimitationimportMessage
            );
            SetTotalDisplayCount(true);
            SetTotalDisplayCsvError(true)
            updateFromChild(true)
            setCsvdata([]);
            document.getElementById("hidelod").style.display = "none";
          }
        });
      });
    }
          
  } catch (error) {
    toast.error("An error occurred. Please try again.");
    document.getElementById("hidelod").style.display = "none";
  }
  };
  const canclecsv = () => {
    resetcsv();
    setCsvdata([]);
    SetMapAttrBox(false);
    document.getElementById("map_attribute").style.display = "none";
    SetTotalDisplayCount(false);
    SetTotalDisplayCsvError(false)
    setOpencsv(false);
  };


  const addbtn = () => {
    history.push({
      pathname: "/caddbookedmeetings",
      state: { Pagename: "BookedMeeting" },
    });
    window.scrollTo(0, 0);
  };

  const CustomColumn = () => {
    history.push({pathname:"/bookedmeetingcustomcolumn", state:{Pagename:"BookedMeeting"}});
  };
  const backbtn = () => {
    history.push("/cbookedmeetings");
  };

  const handledDropdownChange = (e) => {
    setselectedvalue(e.target.value);
  };

  // const filterSearch = () => {
  //   var BokkedMeetingFilterSecArray = [];
  //   Counter.map((newvalue) => {
  //     var searchedVal1 = document.getElementById(
  //       "searchvalue" + newvalue
  //     ).value;
  //     var expressionval = document.getElementById(
  //       "expression" + newvalue
  //     ).value;
  //     var Cloumnval = document.getElementById("ColumnName" + newvalue).value;

  //     BokkedMeetingFilterSecArray.push({
  //       ColumnName: Cloumnval,
  //       expression: expressionval,
  //       searchvalue: searchedVal1,
  //     });
  //   });

  //   var str_in = {
  //     page: 1,
  //     ClientID: ClientID,
  //     rowsPerPage: RowsPerPage,
  //     sort: true,
  //     colsort: sortedBy,
  //     type: "User",
  //     IsApply: true,
  //     Bookedmeetingfilterarray: BokkedMeetingFilterSecArray,
  //   };

  //   const rows1 = Axios({
  //     url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
  //     method: "POST",
  //     data: str_in,
  //   });
  //   // setState({fdata:str_in})
  //   rows1.then((filterresult) => {
  //     setdata(filterresult.data.pagedata);
  //     setrows2(filterresult.data.pagedata);
  //     setrlen(filterresult.data.totalcount);
  //     setflen(filterresult.data.totalcount);
  //     setcountpage(filterresult.data.pagecount);
  //     setfilterdata1(filterresult.data.filterdata);
  //   });
  // };

  // Edit Filter apply for
  // const editapplyfilterSearch = () => {
  //   var BokkedMeetingFilterSecArray = [];
  //   Counter2.map((editnewvalue) => {
  //     var searchedVal1 = document.getElementById(
  //       "editsearchvalue" + editnewvalue
  //     ).value;
  //     var expressionval = document.getElementById(
  //       "editexpression" + editnewvalue
  //     ).value;
  //     var Cloumnval = document.getElementById(
  //       "EditColumnName" + editnewvalue
  //     ).value;

  //     BokkedMeetingFilterSecArray.push({
  //       ColumnName: Cloumnval,
  //       expression: expressionval,
  //       searchvalue: searchedVal1,
  //     });
  //   });

  //   var str_in = {
  //     page: 1,
  //     ClientID: ClientID,
  //     rowsPerPage: RowsPerPage,
  //     sort: true,
  //     colsort: sortedBy,
  //     type: "User",
  //     IsApply: true,
  //     Bookedmeetingfilterarray: BokkedMeetingFilterSecArray,
  //     //  FilterID:filterid,
  //   };

  //   const rows1 = Axios({
  //     url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
  //     method: "POST",
  //     data: str_in,
  //   });
  //   // setState({fdata:str_in})
  //   rows1.then((filterresult) => {
  //     setdata(filterresult.data.pagedata);
  //     setrows2(filterresult.data.pagedata);
  //     setrlen(filterresult.data.totalcount);
  //     setflen(filterresult.data.totalcount);
  //     setcountpage(filterresult.data.pagecount);
  //     setfilterdata1(filterresult.data.filterdata);
  //   });
  // };

  // Edit add filter property
 
  const editsavefilterpropertyfilter = () => {
    try {

    var BokkedMeetingFilterSecArray = [];
    Counter2.map((editnewvalue) => {
      var searchedVal1 = document.getElementById(
        "editsearchvalue" + editnewvalue
      ).value;
      var expressionval = document.getElementById(
        "editexpression" + editnewvalue
      ).value;
      var Cloumnval = document.getElementById(
        "EditColumnName" + editnewvalue
      ).value;

      BokkedMeetingFilterSecArray.push({
        ColumnName: Cloumnval,
        expression: expressionval,
        searchvalue: searchedVal1,
      });
    });
    var filterdropoid = document.getElementById("FilterID").value;

    var str_in = {
      page: 1,
      ClientID: ClientID,
      rowsPerPage: RowsPerPage,
      sort: true,
      colsort: sortedBy,
      type: "User",
      IsApply: false,
      FilterID: filterdropoid,
      UserID: UserID,
      IsSave: true,
      CreatedBy: CUserID,
      CreatedDate: new Date(),
      Bookedmeetingfilterarray: BokkedMeetingFilterSecArray,
      LastUpdatedDate: new Date(),
      LastUpdatedBy:CUserID,
      Role: Role,
    };

    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL + "/bookedmeeting/filtereditbookedmeeting",
      method: "POST",
      data: str_in,
    });

    rows1.then((filterresult) => {
      if (filterresult.statusText == "OK") {
        toast.success(
          "booked meeting filter add successfully.",
          "Edit booked meeting filter"
        );

        setdata(filterresult.data.pagedata);
        //  setState({ data: filterresult.data.pagedata });
        // setState({ rows2: filterresult.data.pagedata });
        // setState({ rlen: filterresult.data.totalcount });
        // setState({ flen: filterresult.data.totalcount });
        // setState({ countpage: filterresult.data.pagecount });
        // setState({filterdata1:filterresult.data.filterdata})
      } else {
        toast.error(filterresult.data.Message);
      }
    });

    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  // Save Filter Property

  const addfilterBookedmeetingfilter = () => {
    try {

    var BokkedMeetingFilterSecArray = [];
    Counter.map((newvalue) => {
      var searchedVal1 = document.getElementById(
        "searchvalue" + newvalue
      ).value;
      var expressionval = document.getElementById(
        "expression" + newvalue
      ).value;
      var Cloumnval = document.getElementById("ColumnName" + newvalue).value;

      BokkedMeetingFilterSecArray.push({
        ColumnName: Cloumnval,
        expression: expressionval,
        searchvalue: searchedVal1,
      });
    });

    var FilterName = document.getElementById("FilterName").value;

    var str_in = {
      page: 1,
      ClientID: ClientID,
      rowsPerPage: RowsPerPage,
      sort: true,
      sortby: sortedBy,
      type: "User",
      IsApply: false,
      FilterName: FilterName,
      FieldFor: "Bookedmeeting",
      UserID: UserID,
      IsSave: true,
      CreatedBy: CUserID,
      CreatedDate: new Date(),
      Bookedmeetingfilterarray: BokkedMeetingFilterSecArray,
      Role: Role,
    };

    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetBookedMeeting",
      method: "POST",
      data: str_in,
    });

    rows1.then((filterresult) => {
      if (filterresult.statusText == "OK") {
        toast.success(
          "Booked meeting filter add successfully.",
          "Add booked meeting filter"
        );

        setdata(filterresult.data.pagedata);
        //  setState({ data: filterresult.data.pagedata });
        // setState({ rows2: filterresult.data.pagedata });
        // setState({ rlen: filterresult.data.totalcount });
        // setState({ flen: filterresult.data.totalcount });
        // setState({ countpage: filterresult.data.pagecount });
        // setState({filterdata1:filterresult.data.filterdata})
      } else {
        toast.error(filterresult.data.Message);
      }
    });
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };


  //Get Custom fields
  const GetCustomFiledsData = (CID) => {
    try {
      const InputParameters = {
        ClientID: CID,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/bookedmeeting/GetCustomFields",
        method: "POST",
        data: InputParameters,
      }).then((res) => {
        let Arr = [
          {
            FieldValue: "AccountName",
            ColumnName: "Account Name",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "ClosedLostDate",
            ColumnName: "Closed Lost Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "ClosedWonDate",
            ColumnName: "Closed Won Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "Company",
            ColumnName: "Company",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "ContractValue",
            ColumnName: "Contract Value",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "CreatedDt",
            ColumnName: "Created Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "DaysInStage",
            ColumnName: "Days In Stage",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "Domain",
            ColumnName: "Domain",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "Email",
            ColumnName: "Email",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "FirstName",
            ColumnName: "First Name",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "Industry",
            ColumnName: "Industry",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "JobTitle",
            ColumnName: "JobTitle",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "LastName",
            ColumnName: "Last Name",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "LastStatusChangeDate",
            ColumnName: "Last Status Change Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "LinkedInUrl",
            ColumnName: "LinkedIn Url",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "EventStartDt",
            ColumnName: "Meeting Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "datetime-local",
          },
          {
            FieldValue: "MeetingOwner",
            ColumnName: "Meeting Owner",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "ResponserName",
            ColumnName: "Meeting Set By",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "EventCreatedDt",
            ColumnName: "Meeting Set Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "MeetingSource",
            ColumnName: "Meeting Source",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "EventStatus",
            ColumnName: "Meeting Status",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "MeetingStatusType",
            ColumnName: "Meeting Status Type",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "OpportunityDate",
            ColumnName: "Opportunity Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "QulifiedDate",
            ColumnName: "Qualified Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "IsSendMeetingReminder",
            ColumnName: "Send Reminder",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "null",
          },
          {
            FieldValue: "Seniority",
            ColumnName: "Seniority",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
          {
            FieldValue: "UnqualifiedDate",
            ColumnName: "Unqualified Date",
            Expression: "Equals",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "date",
          },
          {
            FieldValue: "ReminderEmailAccount",
            ColumnName: "Reminder Email Account",
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            type: "text",
          },
        ];
        res.data.Data.forEach((el) => {
          Arr.push({
            FieldValue: el.FieldName,
            ColumnName: el.FieldName,
            Expression: "Contains",
            FilterPropertyValue: "",
            FilterPropertyValue1: "",
            IsCustom: true,
            CustomFieldID: el._id,
            type: "text",
          });
        });

        let ChangedArr = [];
        Arr.forEach((el, i) => {
          if (
            el.FieldValue == "OpportunityDate" ||
            el.FieldValue == "UnqualifiedDate" ||
            el.FieldValue == "EventCreatedDt" ||
            el.FieldValue == "EventStartDt" ||
            el.FieldValue == "LastStatusChangeDate" ||
            el.FieldValue == "CreatedDt" ||
            el.FieldValue == "ClosedWonDate" ||
            el.FieldValue == "ClosedLostDate" ||
            el.FieldValue == "QulifiedDate"
          ) {
            ChangedArr.push({
              id: i,
              FieldValue: el.FieldValue,
              ColumnName: el.ColumnName,
              Expression: "Equals",
              FilterPropertyValue: "",
              FilterPropertyValue1: "",
              IsCustom: false,
              IsDate: true,
              type: el.type,
            });
          } else {
            if (el.IsCustom == true) {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "Contains",
                FieldType: "Text",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                IsCustom: true,
                IsDate: false,
                CustomFieldID: el.CustomFieldID,
                type: el.type,
              });
            } else {
              ChangedArr.push({
                id: i,
                FieldValue: el.FieldValue,
                ColumnName: el.ColumnName,
                Expression: "Contains",
                FieldType: "",
                FilterPropertyValue: "",
                FilterPropertyValue1: "",
                IsCustom: false,
                IsDate: false,
                type: el.type,
              });
            }
          }
        });

        let SortedArr = ChangedArr.sort(function (a, b) {
          return a.ColumnName.toLowerCase().localeCompare(
            b.ColumnName.toLowerCase()
          );
        });

        SetCustomColumnData(SortedArr);
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  //Column handled Dropdown Change
  const ColumnhandledDropdownChange = (id, Index) => {
    try {
      
    let ColumnName = document.getElementById("ColumnName" + id).value;
      

    let FieldAttributesDatas = [];
    FieldAttributes.forEach((elem) => { FieldAttributesDatas.push(elem);});
    let indexed = CustomColumnData.findIndex((v) => v.FieldValue === ColumnName);
    
    document.getElementById("expression" + id).value = CustomColumnData[indexed].Expression;
   document.getElementById("searchvalue" + id).value = "";

    FieldAttributesDatas[Index].ColumnName = ColumnName;
    FieldAttributesDatas[Index].InputType = CustomColumnData[indexed].Expression;
    FieldAttributesDatas[Index].FilterPropertyValue = "";
    FieldAttributesDatas[Index].FilterPropertyValue1 = "";
    

    FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
    FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;
    FieldAttributesDatas[Index].CustomFieldID = CustomColumnData[indexed].CustomFieldID;
    FieldAttributesDatas[Index].type = CustomColumnData[indexed].type;
    if (ColumnName == "IsSendMeetingReminder") {
      FieldAttributesDatas[Index].IsBool = true;
    } else {
      FieldAttributesDatas[Index].IsBool = false;
    }
    
    SetFieldAttributes(FieldAttributesDatas);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  // Expression Handler drop down change
  const ExpressionHandler = (id) => {
    try {

    let StateArr = [];
    FieldAttributes.forEach((elem) => {
      StateArr.push(elem);
    });
    let ColumnName = document.getElementById("ColumnName" + id).value;
    let InputType = document.getElementById("expression" + id).value;
    let indexed = StateArr.findIndex((v) => v.id === id);
    if (indexed == -1) {
      StateArr.push({ id: id, ColumnName: ColumnName, InputType: InputType });
    } else {
      StateArr[indexed].ColumnName = ColumnName;
      StateArr[indexed].InputType = InputType;
      //StateArr[indexed].IsBool = InputType==="IsBlank" || InputType==="IsNotBlank"?true:false;
    }


    SetFieldAttributes(StateArr);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Add filter property
  const handleClick = () => {
    let StateArray = [];
    FieldAttributes.forEach((elem) => {
      
      StateArray.push(elem);
    });
    
    StateArray.push({
      id: Math.floor(Math.random() * 1000000000),
      ColumnName: "AccountName",
      InputType: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      type:"text"
    });
    
    SetFieldAttributes(StateArray);
  };

  //remove filter property
  const handleRemoveClick = (id) => {
    if (FieldAttributes.length === 1) {
      // Display an error message when trying to remove the last filter property
      toast.error(
        <div className="toastsize">Filter <br /> Required filter property at least one</div>
      );
    } else {
    var countArray2 = [];
    FieldAttributes.map((element) => {
      if (element.id != id) {
        countArray2.push(element);
      }
    });

    SetFieldAttributes(countArray2);
  }
  };

  // filter validation
  const FilterValidation = () => {
    try {

    let ValidationArr = [];

    FieldAttributes.map((newvalue) => {
      if (newvalue.IsBool == false) {
        var searchedVal1 = document.getElementById(
          "searchvalue" + newvalue.id
        ).value;
        if (searchedVal1.trim() == "") {
          ValidationArr.push(false);
        } else {
          ValidationArr.push(true);
        }
        var expressionval = document.getElementById(
          "expression" + newvalue.id
        ).value;
        if (expressionval == "between") {
          let FilterPropertyValue2 = document.getElementById(
            "EndsWith" + newvalue.id
          ).value;
          if (FilterPropertyValue2.trim() == "") {
            ValidationArr.push(false);
          } else {
            ValidationArr.push(true);
          }
        }
      }
    });

    return ValidationArr.includes(false);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  const filterSearch = () => {
    try {
      if (FilterValidation() == false) {
        if (FieldAttributes.length <= 0) {
          toast.error(
            <div className="toastsize">
              {" "}
              Filter <br /> Please add filter property at least one{" "}
            </div>
          );
        } else {
          var BookMeetingFilterSecArray = [];

          FieldAttributes.map((newvalue, i) => {
            var searchedVal1 = document.getElementById(
              "searchvalue" + newvalue.id
            ).value;
            var expressionval = document.getElementById(
              "expression" + newvalue.id
            ).value;
            var ColumnName = document.getElementById(
              "ColumnName" + newvalue.id
            ).value;
            if (expressionval == "between") {
              var searchedVal2 = document.getElementById(
                "EndsWith" + newvalue.id
              ).value;
              BookMeetingFilterSecArray.push({
                ColumnName: ColumnName,
                expression: expressionval,
                searchvalue: searchedVal1?.trim(),
                searchvalue1: searchedVal2?.trim(),
                IsBool: newvalue.IsBool,
                IsCustom: newvalue.IsCustom,
                IsDate: newvalue.IsDate,
              });
            } else {
              BookMeetingFilterSecArray.push({
                ColumnName: ColumnName,
                expression: expressionval,
                searchvalue: searchedVal1?.trim(),
                searchvalue1: null,
                IsBool: newvalue?.IsBool,
                IsCustom: newvalue?.IsCustom,
                IsDate: newvalue?.IsDate,
                CustomFieldID: newvalue?.CustomFieldID,
              });
            }
          });

          SetFilterParameters(BookMeetingFilterSecArray);
          SetIsApply(true);
          SetFieldAttributes([]);
          SetOpenAddFilter(false);
          CloseAllFilterModels();
          SetFieldAttributes([
            {
              id: Math.floor(Math.random() * 1000000000),
              ColumnName: "AccountName",
              InputType: "Contains",
              IsCustom: false,
              IsDate: false,
              IsBool: false,
            },
          ]);
        }
      } else {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Property value can not be an empty
          </div>
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  //Reset Filter
  const ResetFilter = () => {
    SetIsApply(false);
    SetFilterParameters([]);
  };

  //Check filter name exists or not
  const CheckFilterName = async () => {
    try {

    let FilterName = document.getElementById("FilterName").value.trim();
    let flag = false;
    const InputParameters = {
      ClientID: ClientID,
      FilterName: FilterName,
      Role: Role,
    };
    let res = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeeting/CheckBookMeetingFilterName",
      method: "POST",
      data: InputParameters,
    });
    if (res.data.StatusMessage == "SUCCESS") {
      if (res.data.Data <= 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      toast.error("Something went wrong in filter name exists api");
    }

    return flag;
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  // Save Filter Property
  const addfilterBookMeetingfilter = async () => {
    try {
      SetDisabledAddFilter(true);
      let FilterName = document.getElementById("FilterName").value.trim();
      if (FilterName == "") {
        toast.error(
          <div className="toastsize">
            Filter
            <br />
            Please enter filter name
          </div>
        );
        SetDisabledAddFilter(false);
      } else {
        if ((await CheckFilterName()) == false) {
          toast.error(
            <div className="toastsize">
              Filter
              <br />
              Filter value already exist
            </div>
          );
          SetDisabledAddFilter(false);
        } else {
          if (FilterValidation() == false) {
            if (FieldAttributes.length <= 0) {
              toast.error(
                <div className="toastsize">
                  Filter
                  <br />
                  Please add filter property at least one
                </div>
              );
              SetDisabledAddFilter(false);
            } else {
              var BookMeetingFilterSecArray = [];
              FieldAttributes.map((newvalue) => {
                var searchedVal1 = document.getElementById(
                  "searchvalue" + newvalue.id
                ).value;
                var expressionval = document.getElementById(
                  "expression" + newvalue.id
                ).value;
                var Cloumnval = document.getElementById(
                  "ColumnName" + newvalue.id
                ).value;
                let indexed = CustomColumnData.findIndex(
                  (v) => v.FieldValue == Cloumnval
                );
                if (expressionval == "between") {
                  var searchedVal2 = document.getElementById(
                    "EndsWith" + newvalue.id
                  ).value;
                  BookMeetingFilterSecArray.push({
                    ColumnName: Cloumnval,
                    expression: expressionval,
                    searchvalue: searchedVal1?.trim(),
                    searchvalue1: searchedVal2?.trim(),
                    FieldType: CustomColumnData[indexed].FieldType,
                    //new change while save for between it will filter
                    IsBool: newvalue.IsBool,
                    IsCustom: newvalue.IsCustom,
                    IsDate: newvalue.IsDate,
                  });
                } else {
                  BookMeetingFilterSecArray.push({
                    ColumnName: Cloumnval,
                    expression: expressionval,
                    searchvalue: searchedVal1?.trim(),
                    searchvalue1: null,
                    FieldType: CustomColumnData[indexed].FieldType,
                    CustomFieldID: newvalue?.CustomFieldID,
                    IsBool: newvalue?.IsBool,
                    IsCustom: newvalue?.IsCustom,
                  });
                }
              });

              const InputParameters = {
                ClientID: ClientID,
                FilterName: FilterName,
                FilterParameters: BookMeetingFilterSecArray,
                CreatedDate: new Date(),
                UserID: UserID,
              };

              let res = await Axios({
                url:
                  CommonConstants.MOL_APIURL +
                  "/bookedmeeting/SaveBookMeetingFilter",
                method: "POST",
                data: InputParameters,
              });

              if (res.data.StatusMessage == "SUCCESS") {
                SetFilterParameters(BookMeetingFilterSecArray);
                SetIsApply(true);
                CloseAllFilterModels();
                SetFieldAttributes([]);
                SetOpenAddFilter(false);
                SetFieldAttributes([
                  {
                    id: 0,
                    ColumnName: "AccountName",
                    InputType: "Contains",
                    IsCustom: false,
                    IsDate: false,
                    IsBool: false,
                  },
                ]);

                Getfilterlist(ClientID);
                toast.success(
                  <div className="toastsize">
                    Filter
                    <br />
                    Filter Added SuccessFully.
                  </div>
                );
                SetDisabledAddFilter(false);
              } else {
                toast.error("Error in save filter");
                SetDisabledAddFilter(false);
              }
            }
          } else {
            toast.error(
              <div className="toastsize">
                Filter
                <br />
                Property value can not be an empty
              </div>
            );
            SetDisabledAddFilter(false);
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  // Get Filter List
  const Getfilterlist = (CID, URole) => {
    var InputParameters = {
      ClientID: CID,
      Role: URole,
    };

    const rows3 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/bookedmeeting/GetBookMeetingFilterDropDown",
      method: "POST",
      data: InputParameters,
    });
    rows3.then((result) => {
      SetFilterName(result.data.Data);
      
      if (result.data.Data.length > 0) {
        Getfilterpropertylist(result.data.Data[0]._id);
      }
    });
  };

  //Edit Book Meeting Filter Change
  const handleChangeFilter = () => {
    var filterdropoid = document.getElementById("FilterID").value?.trim();
    Getfilterpropertylist(filterdropoid);
  };

  // Get FilteProperty list with dropdown
  const Getfilterpropertylist = (filterid) => {
    // SetFilterID(filterid)
    
    var InputParameters = {
      ClientID: ClientID,
      FilterID: filterid,
      IsDeleted: false,
      Role: Role,
    };
    const rows4 = Axios({
      url: CommonConstants.MOL_APIURL +"/bookedmeeting/GetBookMeetingEditFilterProperties",
      method: "POST",
      data: InputParameters,
    });
    rows4.then((result) => {
      
      let FilteredArr = [];
      result.data?.Data?.forEach((el, i) => {
        
        
        const foundObject = CustomColumnData.find(obj => obj.FieldValue === el.FilterPropertyName);
            // 
          if(foundObject){
            el.id = i;
            el.IsSaved = true;
            if (el.FilterPropertyName == "OpportunityDate" ||el.FilterPropertyName == "UnqualifiedDate" ||el.FilterPropertyName == "EventCreatedDt" ||el.FilterPropertyName == "EventStartDt" ||
              el.FilterPropertyName == "LastStatusChangeDate" ||el.FilterPropertyName == "CreatedDt" ||el.FilterPropertyName == "ClosedWonDate" ||el.FilterPropertyName == "ClosedLostDate" ||el.FilterPropertyName == "QulifiedDate") {
              el.IsDate = true;
              if(el.FilterPropertyName == "EventStartDt"){
                el.type = "datetime-local"
              }else{
                el.type = "date"
    
              }
            } else {
              
              if (el.FieldType == "Text") {
               
                el.CustomFieldID = foundObject.CustomFieldID;
                el.IsCustom = true;
                el.type = "text"
              } else {
                el.IsCustom = foundObject.IsCustom;
                el.CustomFieldID = foundObject.CustomFieldID;
    
                el.type = "text"
              }
              el.IsDate = false;
              el.type = "text"
            }
            if (el.FilterPropertyName == "IsSendMeetingReminder") {
              el.IsBool = true;
            } else {
              el.IsBool = false;
            }
            FilteredArr.push(el);
          }
       
      });
      
      
      SetEditFieldAttributes(FilteredArr);
    });
  };

  //Edit column name
  const EditColumnName = (ID) => {
    try {
      
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });

    let FilterPropertyName = document.getElementById(
      "EditColumnName" + ID
    ).value;
    let indexed = SavedData.findIndex((v) => v.id === ID);
    let Customs = CustomColumnData.findIndex((v) => v.FieldValue == FilterPropertyName);
    document.getElementById("Editexpression" + ID).value = CustomColumnData[indexed].Expression;
    document.getElementById("Editsearchvalue" + ID).value = CustomColumnData[indexed].FilterPropertyValue1;

    SavedData[indexed].FilterPropertyName = FilterPropertyName;
    SavedData[indexed].FilterPropertyValue1 = "";
    SavedData[indexed].FilterPropertyValue2 = "";
    

    if (
      SavedData[indexed].FilterPropertyName == "OpportunityDate" ||
      SavedData[indexed].FilterPropertyName == "UnqualifiedDate" ||
      SavedData[indexed].FilterPropertyName == "EventCreatedDt" ||
      SavedData[indexed].FilterPropertyName == "EventStartDt" ||
      SavedData[indexed].FilterPropertyName == "LastStatusChangeDate" ||
      SavedData[indexed].FilterPropertyName == "CreatedDt" ||
      SavedData[indexed].FilterPropertyName == "ClosedWonDate" ||
      SavedData[indexed].FilterPropertyName == "ClosedLostDate" ||
      SavedData[indexed].FilterPropertyName == "QulifiedDate"
    ) {
      SavedData[indexed].IsDate = true;
      if(SavedData[indexed].FilterPropertyName == "EventStartDt"){
        SavedData[indexed].type = "datetime-local"
      }else{
        SavedData[indexed].type = "date"

      }
    } else {
      SavedData[indexed].IsDate = false;

      if (CustomColumnData[Customs].IsCustom == true) {
        SavedData[indexed].IsCustom = true;
        SavedData[indexed].CustomFieldID = CustomColumnData[Customs].CustomFieldID;
      } else {
        SavedData[indexed].IsCustom = false;
      }

      // if (SavedData[indexed].IsCustom == true) {
      //   SavedData[indexed].IsCustom = true;
      // } else {
      //   SavedData[indexed].IsCustom = false;
      // }
      if (SavedData[indexed].FilterPropertyName == "IsSendMeetingReminder") {
        SavedData[indexed].IsBool = true;
      } else {
        SavedData[indexed].IsBool = false;
      }
    }
    SetEditFieldAttributes(SavedData);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Edit Expression value
  const EditExpressValue = (ID) => {
    try {

    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let ExpressionName = document.getElementById("Editexpression" + ID).value;

    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].ExpressionName = ExpressionName;
    SetEditFieldAttributes(SavedData);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Edit FilterPropertyValue1
  const EditFilterPropertyValue1 = (ID) => {
    try {
      
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyValue1 = document.getElementById(
      "Editsearchvalue" + ID
    ).value
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue1 = FilterPropertyValue1;

    SetEditFieldAttributes(SavedData);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Edit FilterPropertyValue2
  const EditFilterPropertyValue2 = (ID) => {
    try {

    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    let FilterPropertyValue2 = document.getElementById(
      "EditEndsWith" + ID
    ).value
    let indexed = SavedData.findIndex((v) => v.id === ID);
    SavedData[indexed].FilterPropertyValue2 = FilterPropertyValue2;
    SetEditFieldAttributes(SavedData);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Add new filter property
  const handleeditPropertyClick = () => {
    let StateArray = [];
    EditFieldAttributes.forEach((elem) => {
      StateArray.push(elem);
    });
    StateArray.push({
      id: StateArray.length,
      FilterPropertyName: "AccountName",
      ExpressionName: "Contains",
      IsCustom: false,
      IsDate: false,
      IsBool: false,
      IsSave: false,
    });
    SetEditFieldAttributes(StateArray);
  };

  //Remove filter property
  const handleEditRemoveClick = (id) => {
  
    let SavedData = [];
    EditFieldAttributes.forEach((elem) => {
      SavedData.push(elem);
    });
    if (SavedData.length <= 1) {
      toast.error(
        <div>
          Filter
          <br />
          Required filter property at least one
        </div>
      );
    } else {
      let DeletedArr = DeletedFilterProperty;
      const FilterPropertyID = SavedData.findIndex((item) => item.id == id);

      if (SavedData[FilterPropertyID].IsSaved == true) {
        DeletedArr.push(SavedData[FilterPropertyID]._id);
        SetDeletedFilterProperty(DeletedArr);
      }
      const FilteredData = SavedData.filter((item) => item.id !== id);
      SetEditFieldAttributes(FilteredData);
    }
  };

  const handleExistingfilter=()=>{
        
        if(FilterName.length>0){
          
          setIsPopupOpen(true);
          SetOpenEditFilter(true);
        }else{

          toast.error(
            <div className="toastsize">
              Filter
              <br />
              No filter available
            </div>
          );
        }
  }

   
  //Apply Edit Filter
  const ApplyEditFilter = () => {
    
    
    if (UpdateFilterValidation() == false) {

    var BookMeetingFilterSecArray = [];
    
    EditFieldAttributes.map((el) => {
      if (el.ExpressionName == "between") {
        BookMeetingFilterSecArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1?.trim(),
          searchvalue1: el.FilterPropertyValue2?.trim(),
          CustomFieldID:el.CustomFieldID,
          IsCustom:el.IsCustom,
          IsBool:el.IsBool,
          IsDate:el.IsDate
        });
      } else {
        BookMeetingFilterSecArray.push({
          ColumnName: el.FilterPropertyName,
          expression: el.ExpressionName,
          searchvalue: el.FilterPropertyValue1?.trim(),
          searchvalue1: null,
          CustomFieldID:el.CustomFieldID,
          IsCustom:el.IsCustom,
          IsBool:el.IsBool,
          IsDate:el.IsDate
          
        });
      }
    });
    
    
    SetFilterParameters(BookMeetingFilterSecArray);
    SetIsApply(true);
    SetOpenAddFilter(false);
    CloseAllFilterModels();
  }else {
    toast.error(
      <div className="toastsize">
        Filter
        <br />
        Property value can not be an empty
      </div>
    );
  }}

  //Update Filter validation
  const UpdateFilterValidation = () => {
    try {

    let Flags = [];
    EditFieldAttributes.forEach((el) => {
      
      var searchedVal1 = document.getElementById(
        "Editsearchvalue" + el.id
      ).value;

      if (searchedVal1.trim() == "") {
        Flags.push(false);
      } else {
        Flags.push(true);
      }
      if (el.ExpressionName == "between") {
        let FilterPropertyValue2 = document.getElementById(
          "EditEndsWith" + el.id
        ).value;
        if (FilterPropertyValue2.trim() == "") {
          Flags.push(false);
        } else {
          Flags.push(true);
        }
      }
    });

    return Flags.includes(false);
  } catch (error) {
    toast.error("An error occurred. Please try again.");
  }
  };

  //Update and apply filter
  const editapplyfilterSearch = async () => {
    try {
    if (UpdateFilterValidation() == false) {
      let FilterID =  document.getElementById("FilterID").value?.trim()
      const InputParameters = {
        ClientID: ClientID,
        FilterParameters: EditFieldAttributes,
        LastUpdatedDate: new Date(),
        UserID: UserID,
        FilterID: FilterID,
        DeletedFilterProperty: DeletedFilterProperty,
      };

      let res = await Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/bookedmeeting/UpdateBookMeetingFilterProperties",
        method: "POST",
        data: InputParameters,
      });

      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          <div className="toastsize">
            Filter <br />
            Filter Updated SuccessFully.
          </div>
        );
        var BookMeetingFilterSecArray = [];
        EditFieldAttributes.map((el) => {
          if (el.ExpressionName == "between") {
            BookMeetingFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1?.trim(),
              searchvalue1: el.FilterPropertyValue2?.trim(),
              IsCustom:el.IsCustom,
              IsBool:el.IsBool,
              IsDate:el.IsDate
            });
          } else {
            BookMeetingFilterSecArray.push({
              ColumnName: el.FilterPropertyName,
              expression: el.ExpressionName,
              searchvalue: el.FilterPropertyValue1?.trim(),
              searchvalue1: null,
              CustomFieldID:el.CustomFieldID,
          IsCustom:el.IsCustom,
          IsBool:el.IsBool,
          IsDate:el.IsDate
            });
          }
        });
        SetFilterParameters(BookMeetingFilterSecArray);
        SetIsApply(true);
        SetOpenAddFilter(false);
    CloseAllFilterModels();

      } else {
        toast.error("Error in save filter");
      }
    } else {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Property value can not be an empty
        </div>
      );
    }
          
  } catch (error) {
      
  }
  };

  //close edit filter
  const CloseEditFilter = () => {
    SetEditFieldAttributes([]);
    SetOpenAddFilter(false);
    SetFieldAttributes([]);
    SetFieldAttributes([
      {
        id: 0,
        FilterPropertyName: "AccountName",
        ExpressionName: "Contains",
        IsCustom: false,
        IsDate: false,
        IsBool: false,
        IsSave: false,
      },
    ]);
    SetIsApply(false);
    SetOpenAddFilter(false);
    Getfilterlist(ClientID);
  };

  //Open add Filter model
  const AddFilterMethod = () => {
    SetOpenAddFilter(true);
    SetFieldAttributes([
      [
        {
          id: Math.floor(Math.random() * 1000000000),
          ColumnName: "AccountName",
          InputType: "Contains",
          IsCustom: false,
          IsDate: false,
          IsBool: false,
        },
      ],
    ]);
  };


    // change display rows
    const ChangeRowSelected = (Event) => {
      SetCSVERowsPerPage(Number(Event.target.value));
      SetCSVEPage(1);
      const totalPages = Math.ceil(
        Data.length / Number(Event.target.value)
      );
      SetCountPage(totalPages);
     // document.getElementById("hideloding").style.display = "block";
    };

// Search details

const handleSearch = (e) => {
  SetSearch(e.target.value);
};

let filteredData = Data.filter((item) => {
  return Object.keys(item).some((key) =>
    item[key].toString().toLowerCase().includes(Search.toLowerCase())
  );
});

const handleSort = (key) => {
  SetSortField(key);
};

if (SortField !== "") {
  filteredData.sort((a, b) => {
    if (a[SortField] < b[SortField]) {
      return -1;
    }
    if (a[SortField] > b[SortField]) {
      return 1;
    }
    return 0;
  });
}
var currentItems
const handlePagination = (event,pageNumber) => {
  SetCSVEPage(pageNumber);
  const indexOfLastItem = pageNumber * CSVERowsPerPage;
  const indexOfFirstItem = indexOfLastItem - CSVERowsPerPage;
  currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
};

const indexOfLastItem = CSVEPage * CSVERowsPerPage;
const indexOfFirstItem = indexOfLastItem - CSVERowsPerPage;
currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


//Open Filter Model

const OpenFilterModel = async() => {
 await Getfilterlist(ClientID, Role)
  SetOpenAddFilter(true);
    SetFieldAttributes(
      [
        {
          id: Math.floor(Math.random() * 1000000000),
          ColumnName: "AccountName",
          InputType: "Contains",
          IsCustom: false,
          IsDate: false,
          IsBool: false,
        },
      ],
    );
}

//Open Add filter
const OpenAddFilterModel = () => {
  SetOpenAddNewFilter(true)
}

//Close All filter Models
const CloseAllFilterModels = () => {
  SetOpenEditFilter(false)
  SetOpenAddFilter(false)
  SetOpenAddNewFilter(false)
}


  return (
    <>
      <div id="hidelod" className="loding-display">
        <img src={loadingi} />
      </div>
      <Popup open={OpenAddFilter}>
                        <div>
                          <div className="modal-black"></div>
                          <div className="filterPopup">
                            <div className="paddingboxTerms">
                              <div className="modal-header py-4 px-3">
                                <div className="w-100 d-flex px-3">
                                  <h5 className="mb-0">Filter</h5>
                                  <button className="close"  onClick={CloseAllFilterModels}>
                                    <span  aria-hidden="true" className="la la-remove"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="modal-body">
                                <div className="">
                                  <div className="row">
                                    <div className="col-xl-6 allcentertext">
                                      <i className="la la-filter iconsfonts"></i>
                                      
                                          <button className="btn btn-md btn-primary btnColor" onClick={OpenAddFilterModel}>
                                            <span className="Mobile_button">
                                              Add New Filter
                                            </span>
                                          </button>
                                       
                                    </div>
                                    <div className="col-xl-6 allcentertext brdr-left">
                                      <i className="la la-filter iconsfonts"></i>
                                    
                                            <button
                                              className="btn btn-md btn-primary btnColor"
                                              onClick={handleExistingfilter}
                                            >
                                              Select from existing Filter
                                            </button>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>

      {/* Add Filter Popup */}
      <Popup open={OpenAddNewFilter}>
        <div>
          <div className="filterPopup largerPopup1000">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-2">
                <div className="w-100 d-fOpenAddFilterlex px-3">
                  <button
                    className="close px-0 py-0"
                    onClick={CloseAllFilterModels}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </button>
                  <h5 className="mb-0 text-left">New Filter</h5>
                </div>
              </div>
              <div className="px-2 py-2">
                <div className="modal-body px-3">
                  <div className="col-sm-6 mb-5 px-0">
                    <div className="row mb-3">
                      <label className="col-lg-5 col-form-label text-left">
                        Filter Name
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val-required="Please enter title"
                          id="FilterName"
                          name="FilterName"
                          placeholder="Filter Name"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-bordered">
                    <table className="table table-hover" id="tab_logic">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Property </TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {FieldAttributes.map((itemcount, i) => (
                              <TableRow
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                key={i}
                              >
                                <TableCell align="right">
                                  <select
                                    className="form-control m-input"
                                    id={'ColumnName' + itemcount.id}
                                    onChange={() => {ColumnhandledDropdownChange(itemcount.id, i);}}
                                    value={itemcount.FieldValue}
                                    autocomplete="off"
                                  >
                                    {CustomColumnData.map((el) => (
                                      <option value={el.FieldValue}>{' '}{el.ColumnName}{' '}</option>
                                    ))}
                                  </select>
                                </TableCell>
                                <TableCell>
                                  {itemcount.IsCustom == true ? (
                                    <select
                                      className="form-control m-input"
                                      id={'ColumnName' + itemcount.id}
                                      // onChange={()=>{ColumnhandledDropdownChange(itemcount.id)}}
                                      // value={selectedvalue}
                                      autocomplete="off"
                                    >
                                      <option id="text" value="Text">{' '}Text</option>
                                    </select>
                                  ) : null}
                                </TableCell>
                                <TableCell align="right">
                                  {itemcount.IsBool == true ? (
                                    <div>
                                      <select
                                        id={'expression' + itemcount.id}
                                        // onChange={()=>{ExpressionHandler(itemcount.id)}}
                                        className="form-control m-input"
                                      >
                                        <option id="true" value="True">Yes</option>
                                        <option id="false" value="False"> No</option>
                                      </select>
                                      <input
                                        type="hidden"
                                        readOnly
                                        id={'searchvalue' + itemcount.id}
                                        value="y"
                                        className="form-control m-input required"
                                      />
                                    </div>
                                  ) : itemcount.IsDate == true ? (
                                    <select
                                      id={'expression' + itemcount.id}
                                      className="form-control m-input"
                                      onChange={() => {ExpressionHandler(itemcount.id)}}
                                    >
                                      <option id="Equals" value="Equals">Equals</option>
                                      <option id="Lessthan" value="LessThan">{' '}Less Than{' '}</option>
                                      <option id="LessThanEqualTo" value="LessThanEqualTo">{' '}Less Than Equal To{' '}</option>
                                      <option id="greaterthan" value="GreaterThan">{' '}Greater Than{' '}</option>
                                      <option id="greaterthanequalto" value="GreaterThanEqaulTo">{' '}Greater Than Equal To{' '}</option>
                                      <option id="between" value="between">{' '}Between{' '}</option>
                                    </select>
                                  ) : (
                                    <select
                                      id={'expression' + itemcount.id}
                                      onChange={() => {ExpressionHandler(itemcount.id);}}
                                      className="form-control m-input"
                                    >
                                      <option id="Contains" value="Contains">Contains</option>
                                      <option id="EqualTo" value="EqualTo">Equals</option>
                                      <option id="DoesNotEqual/DoesNotContain" value="DoesNotEqual/DoesNotContain">Does Not Equal / Does Not Contain</option>
                                      <option id="IsBlank" value="IsBlank">Is Blank</option>
                                      <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                    </select>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {itemcount.IsBool == true ? null : itemcount.IsDate == true ? (
                                    itemcount.InputType == 'between' ? (
                                      <>
                                        <input
                                          type={itemcount.type}
                                          id={'searchvalue' + itemcount.id}
                                          className="form-control m-input required"
                                        />
                                        <input
                                          type={itemcount.type}
                                          id={'EndsWith' + itemcount.id}
                                          className="form-control m-input required"
                                        />
                                      </>
                                    ) : (
                                      <input
                                        type={itemcount.type}
                                        id={'searchvalue' + itemcount.id}
                                        className="form-control m-input required"
                                      />
                                    )
                                  ) : (
                                    itemcount.InputType == 'IsNotBlank' || itemcount.InputType == 'IsBlank'  ? (<input
                                      type="hidden"
                                      readOnly
                                      id={'searchvalue' + itemcount.id}
                                      value="y"
                                      className="form-control m-input required"
                                    />):
                                    (<input
                                      type={itemcount.type}
                                      id={'searchvalue' + itemcount.id}
                                      className="form-control m-input required"
                                    />)
                                  )}
                                </TableCell>
                                <TableCell align="right"></TableCell>

                                <TableCell align="left">
                                  <button
                                    type="button"
                                    id={'delete' + itemcount.id}
                                    className="btn btngroup"
                                    onClick={() => { handleRemoveClick(itemcount.id);}}
                                  >
                                    <i className="la flaticon-delete-1"></i>
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </table>
                  </div>
                  <div className="row mb-5 pb-5">
                    <div className="col-sm-12 text-left mb-5 pb-5">
                      <button
                        type="button"
                        className="btn btngroup"
                        value="ADD Row"
                        onClick={() => { handleClick(); }}
                      >
                        <i className="la la-plus la-icon-font-size-13"></i> Add
                        Property
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right mt-5">
                  <div className="row mt-2 mx-4">
                    <div className="col-lg-12 pull-right pb-4">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => {
                          // close();
                          CloseAllFilterModels()
                          SetFieldAttributes([
                            {
                              id: 0,
                              FilterPropertyName: 'AccountName',
                              ExpressionName: 'Contains',
                              IsCustom: false,
                              IsDate: false,
                              IsBool: false,
                              IsSave: false,
                            },
                          ]);
                        }}
                      >
                        Cancel
                      </a>
                      <a
                        id="submit"
                        className="btn btn-primary btn-lightgreen mr-1"
                        onClick={() => { filterSearch(); }}
                        value="Apply"
                      >
                        Apply
                      </a>
                      <button
                        id="submit"
                        className="btn btn-primary btn-lightgreen mr-1"
                        onClick={() => {addfilterBookMeetingfilter();}}
                        value="Save"
                        disabled={DisabledAddFilter}
                      >
                        <i className="la la-save"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      {/* Select Filter Model */}
      <Popup open={OpenEditFilter}>
      <div>
  <div className="modal-black"></div>
  <div className="filterPopup largerPopup1000">
    <div className="paddingboxTerms">
      <div className="modal-header py-4 px-3">
        <div className="w-100 d-flex px-3">
          <h5 className="mb-0">Edit Filter</h5>
          <button className="close" onClick={CloseAllFilterModels}>
            <span aria-hidden="true" className="la la-remove"></span>
          </button>
        </div>
      </div>
      <div className="p-3">
        <div className="modal-body">
          <div className="col-sm-6 mb-5 px-0">
            <div className="row mb-3">
              <label className="col-lg-5 col-form-label text-left"> Filter Name</label>
              <div className="col-lg-7">
                <select className="form-control m-input m--hide" id="FilterID" name="FilterID" onChange={() => {handleChangeFilter();}}>
                  {FilterName && FilterName.map((valuefilter) => (
                      <option value={valuefilter._id} key={valuefilter._id}>{valuefilter.FilterName} </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="table-bordered">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Property </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {EditFieldAttributes.map((itemcount, i) => (
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0,}}} key={itemcount.id}>
                      <TableCell align="right">
                        <select className="form-control m-input" id={'EditColumnName' + itemcount.id}
                          onChange={() => {EditColumnName(itemcount.id, i);}}
                          value={itemcount.FilterPropertyName}
                          autocomplete="off"
                        >
                          {CustomColumnData.map((el) => (
                            <option value={el.FieldValue}> {' '}{el.ColumnName}{' '}</option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell>
                        {itemcount.IsCustom == true ? (
                          <select
                            className="form-control m-input"
                            id={'EditColumnName' + itemcount.id}
                            // onChange={()=>{EditColumnName(itemcount.id)}}
                            // value={selectedvalue}
                            autocomplete="off"
                          >
                            <option id="text" value="Text"> {' '} Text</option>
                          </select>
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        {itemcount.IsBool == true ? (
                          <div>
                            <select
                              id={'Editexpression' + itemcount.id}
                              onChange={() => { EditExpressValue(itemcount.id);}}
                              value={itemcount.ExpressionName}
                              className="form-control m-input"
                            >
                              <option id="true" value="True"> Yes</option>
                              <option id="false" value="False"> No</option>
                            </select>
                            <input type="hidden" readOnly id={'Editsearchvalue' + itemcount.id} value="y" className="form-control m-input required"/>
                          </div>
                        ) : itemcount.IsDate == true ? (
                          <select
                            id={'Editexpression' + itemcount.id}
                            className="form-control m-input"
                            onChange={() => { EditExpressValue(itemcount.id);}}
                            value={itemcount.ExpressionName}
                          >
                            <option id="Equals" value="Equals"> Equals</option>
                            <option id="Lessthan" value="LessThan"> {' '} Less Than{' '} </option>
                            <option id="LessThanEqualTo" value="LessThanEqualTo"> {' '} Less Than Equal To{' '}</option>
                            <option id="greaterthan" value="GreaterThan"> {' '}Greater Than{' '}</option>
                            <option id="greaterthanequalto" value="GreaterThanEqaulTo">{' '} Greater Than Equal To{' '}</option>
                            <option id="between" value="between">{' '} Between{' '}</option>
                          </select>
                        ) : (
                          <select
                            id={'Editexpression' + itemcount.id}
                            onChange={() => { EditExpressValue(itemcount.id);}}
                            value={itemcount.ExpressionName}
                            className="form-control m-input"
                          >
                            <option id="Contains" value="Contains">Contains</option>
                            <option id="EqualTo" value="EqualTo"> Equals</option>
                            <option id="DoesNotEqual/DoesNotContain" value="DoesNotEqual/DoesNotContain">Does Not Equal / Does Not Contain</option>
                            <option id="IsBlank" value="IsBlank">Is Blank</option>
                            <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                          </select>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {itemcount.IsBool == true ? null : itemcount.IsDate ==
                          true ? (
                          itemcount.ExpressionName == 'between' ? (
                            <>
                              <input
                                type={itemcount.type}
                                id={'Editsearchvalue' + itemcount.id}
                                onChange={() => {EditFilterPropertyValue1(itemcount.id);}}
                                value={itemcount.FilterPropertyValue1}
                                className="form-control m-input required"
                              />
                              <input
                                type={itemcount.type}
                                id={'EditEndsWith' + itemcount.id}
                                onChange={() => {EditFilterPropertyValue2(itemcount.id);}}
                                value={itemcount.FilterPropertyValue2}
                                className="form-control m-input required"
                              />
                            </>
                          ) : (
                            <input
                              type={itemcount.type}
                              id={'Editsearchvalue' + itemcount.id}
                              onChange={() => { EditFilterPropertyValue1(itemcount.id);}}
                              value={itemcount.FilterPropertyValue1}
                              className="form-control m-input required"
                            />
                          )
                        ) : (
                          itemcount.ExpressionName == 'IsBlank' || itemcount.ExpressionName == 'IsNotBlank' ? ( <input
                            type="hidden"
                            id={'Editsearchvalue' + itemcount.id}
                            readOnly
                            value="y"
                            className="form-control m-input required"
                          />):(
                          <input
                            type="text"
                            id={'Editsearchvalue' + itemcount.id}
                            onChange={() => {EditFilterPropertyValue1(itemcount.id);}}
                            value={itemcount.FilterPropertyValue1}
                            className="form-control m-input required"
                          />)
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <button
                          type="button"
                          id={'delete' + itemcount.id}
                          className="btn btngroup"
                          onClick={() => {handleEditRemoveClick(itemcount.id);}}
                        >
                          <i className="la flaticon-delete-1"></i>
                        </button>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="row">
            <div className="col-sm-12 text-left">
              <button
                type="button"
                className="btn btngroup"
                onClick={() => { handleeditPropertyClick();}}
              >
                <i className="la la-plus la-icon-font-size-13"></i> Add Property{' '}
              </button>
            </div>
          </div>
        </div>
        <div className="border-top modal-content text-right">
          <div className="row">
            <div className="col-lg-12 pull-right">
              <a
                id="backtolist"
                className="btn btn-secondary mr-2"
                onClick={CloseAllFilterModels}
              >
                Cancel
              </a>
              <a
                id="submit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={() => {ApplyEditFilter();}}
              >
                Apply
              </a>
              <a
                id="submit"
                className="btn btn-primary btn-lightgreen mr-1"
                onClick={() => {editapplyfilterSearch();}}
              >
                <i className="la la-save"></i> Save
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </Popup>

      <div className="tabdes">
        <div
        // className={classes.root}
        >
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange} variant="scrollable"
              aria-label="simple tabs example"
            >
              <Tab label="Meetings" {...a11yProps(0)} />
              <Tab label="Meetings Insights" {...a11yProps(1)} />
              <Tab label="Pipeline" {...a11yProps(2)} />
              <Tab label="Meetings By Meeting Source" {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          {/* booking meeting */}

          <TabPanel value={value} className="tagpaddnone px-in" index={0}>
            <div className="white-bg">
            <div className="mb-4">
              <div className="row">
                <div className="col">
                  <div className="bg-white boxcardcounter">
                    <div className="line-title">
                      <span className="clr-sky box-main-title">
                        {totalcount === undefined ? 0 : totalcount}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total Meetings</string>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white boxcardcounter">
                    <div className="line-title">
                      <span className="clr-sky box-main-title">
                        {Won == undefined ? 0 : Won}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total Closed Won</string>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white boxcardcounter">
                    <div className="line-title">
                      <span className="clr-sky box-main-title">
                        {lost == undefined ? 0 : lost}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Total Closed Lost</string>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="bg-white boxcardcounter">
                    <div className="line-title">
                      <span className="clr-sky box-main-title">
                        {percent == undefined ? 0 : percent}
                      </span>
                    </div>
                    <div className="box-inner-title xs-headertitle">
                      <string className="clr-sky">Closed Won Rate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-4">
            <div class="col-xl-12">
              <div className="bg-white w-100 p-3">
                <div id="chart chart-max450">
                  <ReactApexchart
                    options={state.options}
                    series={state.series}
                    type="line"
                    height={450}
                  />
                </div>
              </div>
            </div>
          </div>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-md-6 minheight">
                  <div className="bg-white h-100 overflow-hidden">
                    <div className="chartboxsmet">
                      <h6 className="chartheading">Meeting Status</h6>
                      <ReactFusioncharts
                        key={JSON.stringify(chartdata)}
                        type="pie3d"
                        width="100%"
                        height="100%"
                        dataFormat="JSON"
                        dataSource={chartdata}
                        dataEmptyMessage="No Data"
                      />
                    </div>
                  </div>
                  </div>
                  <div className="col-md-6 minheight">
                  <div className="bg-white h-100">
                    <div className="chartboxsmet">
                    <h6 className="chartheading">Meeting Source</h6>
                      <ReactFusioncharts
                        key={JSON.stringify(chartsourcedata)}
                        type="pie3d"
                        width="100%"
                        height="100%"
                        dataFormat="JSON"
                        dataSource={chartsourcedata}
                        dataEmptyMessage="No Data"
                      />
                      {/* <ReactFusioncharts
                          type="pie3d"
                          width="100%" 
                          height="100%"
                          dataFormat="JSON"
                          dataSource={chartsourcedata}
                          dataEmptyMessage="No Data"
                /> */}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-1 mt-3 mx-0 bg-white border-bottom-0">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left py-4 pl-0">
                  Booked Meetings
                </h4>

                <div className="listing-li float-right padb-15 pr-0">
                  <ul>
                    <li>
                      <a
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        onClick={importcsv}
                      >
                        <i className="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      {
                        ExportDisplayData && ExportDisplayData.length>0?( <a 
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={exportcsv}
                        >
                          <i className="la la-icon-font-size-13 la-download"></i>
                          <span>
                              Export
                          </span>
                        </a>):(<></>)
                      }
                    </li>
                    <li>
                      <a
                        onClick={addbtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i className="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li>
                      <button
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        onClick={() => {
                          OpenFilterModel();
                        }}
                      >
                        <i className="fa la-icon-font-size-13 fa-filter"></i>

                        <span> Add Filter</span>
                      </button>
                     
                    </li>
                    <li>
                      <a
                        onClick={CustomColumn}
                        className="btn btngroup m-btn m-btn--custom"
                      >
                        <i className="la flaticon-interface-8 la-icon-font-size-13"></i>
                        <span>Custom Column</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> 
            </div>
            
            {opencsv ? (
              <div className="portletbody" id="import_csv">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 border-bottom pb-3">
                    <h3 className="uploadheadingtitle">Import Meeting Details</h3>
                    <span class="m-alert alert-paragraph">
                          <i class="la la-info-circle"></i> A sample file is available for download,
                          <CSVDownloader
                                data={SampleExportData}
                                filename={"Sample"}
                                bom={true}
                              >
                        <a > please click here</a>
                            </CSVDownloader>
                        
                    </span>
                    <div className="uplodfilesbox">
                      <CSVReader
                        accept={[
                          "text/csv",
                          ".csv",
                          "application/vnd.ms-excel",
                        ]}
                        onDrop={handleOnDrop}
                        onError={handleOnError}
                        addRemoveButton
                        removeButtonColor="#659cef"
                        onRemoveFile={handleOnRemoveFile}
                        isReset={reset}
                      >
                        <span>Select .CSV file</span>
                        <p>
                          Add booked meeting data from csv file to bulk upload.
                        </p>
                      </CSVReader>
                    </div>
                  </div>
                  <div className="col-xl-8 offset-xl-2 pt-3">
                    <div className="alignright">
                      <a
                        onClick={AccountMapCsv}
                        className="btn btn-primary btnColor"
                      >
                        <i className="la la-upload"></i> Upload
                      </a>
                      <a onClick={canclecsv} className="btn btn-secondary">
                        <i className="la flaticon-cancel"></i> Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
           
              <div className="portletbody portletshadow p-4 m-1" style={{ display: "none" }} id="map_attribute">
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Map attributes</h4>
                </div>
                <div className="row" id="divContactSelection">
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="FirstName">
                            First Name
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="FirstName"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            FirstName == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "firstname" ||
                                  item.toLowerCase() === "first name"
                              )
                              : FirstName
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="LastName">
                            Last Name
                          </option> 
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="LastName"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            LastName == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "lastname" ||
                                  item.toLowerCase() === "last name"
                              )
                              : LastName
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Title">
                            Title
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Title"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Title == null ? DropBoxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "title"
                            ) : Title
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Company">
                            Company
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Company"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Company == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "company"
                              )
                              : Company
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Email">
                            Email
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Email"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Email == null ? DropBoxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "email"
                            ) : Email
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="LinkedInURL">
                            LinkedIn Url
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="LinkedInURL"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            LinkedInURL == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "linkedinurl" ||
                                  item.toLowerCase() === "linkedin url"
                              )
                              : LinkedInURL
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingStatus">
                            Meeting Status
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingStatus"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingStatus == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingstatus" ||
                                  item.toLowerCase() === "meeting status"
                              )
                              : MeetingStatus
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingSources">
                            Meeting Source
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingSources"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingSources == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingsource" ||
                                  item.toLowerCase() === "meeting source"
                              )
                              : MeetingSources
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingSetDate">
                            Meeting Set Date
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingSetDate"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingSetDate == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingsetdate" ||
                                  item.toLowerCase() === "meeting set date"
                              )
                              : MeetingSetDate
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingTimeZone">
                            Meeting Time Zone
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingTimeZone"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingTimeZone == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingtimezone" ||
                                  item.toLowerCase() === "meeting time zone"
                              )
                              : MeetingTimeZone
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingOwnerID">
                            Owner
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingOwnerID"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingOwner == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "owner" 
                              )
                              : MeetingOwner
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="ClientPOCID">
                            Reminder Email Account
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="ClientPOCID"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            ClientPOCID == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "reminder email account" ||
                                  item.toLowerCase() === "reminder email account"
                              )
                              : ClientPOCID
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="ContractValue">
                            Contract Value
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="ContractValue"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            ContractValue == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "contractvalue" ||
                                  item.toLowerCase() === "contract value"
                              )
                              : ContractValue
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Industry">
                            Industry
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Industry"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Industry == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "industry"
                              )
                              : Industry
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="DirectPhone">
                            Direct Phone
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="DirectPhone"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            DirectPhone == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "directphone" ||
                                  item.toLowerCase() === "direct phone"
                              )
                              : DirectPhone
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="CompanyPhone">
                            Company Phone
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="CompanyPhone"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            CompanyPhone == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "companyphone" ||
                                  item.toLowerCase() === "company phone"
                              )
                              : CompanyPhone
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingSetBy">
                            Meeting Set By
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingSetBy"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingSetBy == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingsetby" ||
                                  item.toLowerCase() === "meeting set by"
                              )
                              : MeetingSetBy
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="MeetingDate">
                            Meeting Date
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="MeetingDate"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            MeetingDate == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "meetingdate" ||
                                  item.toLowerCase() === "meeting date"
                              )
                              : MeetingDate
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="SendReminder">
                            Send Reminder
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="SendReminder"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            SendReminder == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "sendreminder" ||
                                  item.toLowerCase() === "send reminder"
                              )
                              : SendReminder
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="DirectPhoneExt">
                          Direct Phone Ext
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="DirectPhoneExt"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            DirectPhone == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "directphoneext" ||
                                  item.toLowerCase() === "direct phone ext"
                              )
                              : DirectPhone
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Website">
                            Website
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Website"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Website == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "website" 
                              )
                              : Website
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="NoteTitle">
                            Notes Title
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="NoteTitle"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            NoteTitle == null
                              ? DropBoxData.findIndex(
                                (item) =>
                                  item.toLowerCase() === "notestitle" ||
                                  item.toLowerCase() === "notes title"
                              )
                              : NoteTitle
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group m-form__group row mx-0">
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select
                          className="dbcontent form-control  m-input"
                          disabled="disabled"
                        >
                          <option selected="" value="Notes">
                            Notes
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 text-center">
                      <span className="fa fa-arrows-h fa-2x "></span>
                    </div>
                    <div className="col-lg-5 nopadding">
                      <div>
                        <select disabled={MapAttrBox}
                          id="Notes"
                          className="csvcontent form-control  m-input"
                          autocomplete="off"
                          value={
                            Notes == null ? DropBoxData.findIndex(
                              (item) =>
                                item.toLowerCase() === "notes"
                            ) : Notes
                          }
                        >
                          <option value="">Not Mapped</option>
                          {DropBoxData.map((dbdata, index) => (
                            <option value={index}>{dbdata}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                      {
                        MeetingCustomFieldData?.map((customfield)=>{
                          
                          return <>
                        <div className="col-lg-6 form-group m-form__group row mx-0">
                          <div className="col-lg-5 nopadding">
                      <select
                        className="dbcontent form-control  m-input"
                        id="dbColumn23"
                        disabled="disabled"
                      >
                        <option selected="" value={customfield?.FieldName}>
                        {customfield?.FieldName}
                        </option>
                      </select>
                      <div>
                    </div>
                          </div>

                          <div className="col-lg-2 text-center">
                            <span className="fa fa-arrows-h fa-2x "></span>
                          </div>

                          <div className="col-lg-5 nopadding"> 
                            <div>
                              <select
                                id={customfield?.FieldName}
                                className="csvcontent form-control  m-input"
                                autocomplete="off"
                                value={DropBoxData.findIndex(
                                      (item) =>
                                      item.toLowerCase() === customfield?.FieldName.toLowerCase()
                                      )}
                              >
                                <option>Not Mapped</option>
                                {DropBoxData.map((dbdata, index) => (
                                  <option value={index}>{dbdata}</option>
                                ))}
                              </select>
                            </div> 
                          </div> 
                        </div>
                          </>
                        })
                      }

                  <div className="col-lg-12 form-group m-form__group d-flex ml-3">
                      <div className="toggleswich">
                        <input
                          type="checkbox"
                          className="checktogle"
                          id="IsUpdateExistContacts"
                        />
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>
                      <label>Skip updating existing contacts</label>
                    </div>
                  <div className="col-lg-12 py-3 px-3 border-top">
                    <button
                      onClick={Savecsv}
                      disabled={MapAttrBox}
                      className="btn btn-primary btn-lightgreen"
                    >
                      <i className="la la-save"></i>Save
                    </button>
                  </div>
                </div>
              </div>
           
            {TotalDisplayCount ? (
              <div className="portletbody px-4" id=" ">
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Results</h4>
                </div>

                <div className="col-xs-12">
                  <div className="row colfive">
                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-primary">
                                {TotalResult}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-success">
                                {TotalSuccess}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total success</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-danger">
                                {TotalError}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total error</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-warning">
                                {TotalDuplicate}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total Duplicate</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                     <div className="col-md-3">
                      <div className="shadowcard boxcardcounter">
                        <div className="d-flex align-center">
                          <div className="contectboxes w-100">
                            <div className="line-title mb-2">
                              <span className="box-main-title font-danger">
                                {TotalSkiped}
                              </span>
                            </div>
                            <div className="box-inner-title xs-headertitle">
                              <string className="clr-sky">Total Skipped</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-xs-12">
                  <p className="float-left text-danger">
                    {ContactimportlimitaionMessage + "*"}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* Csv Error Code start */}
            {
              TotalDisplayCsvError?(<>            
              <div className="bg-white my-2">
                <div className="row">
                  <h5>
                    Csv Error Details
                  </h5>
                </div>

                {/* table use */}
                <div className="row padt-25">
                  <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">
                      Show
                      <select
                        name="tbl_meeting_length"
                        onChange={
                          ChangeRowSelected
                        }
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        value={CSVERowsPerPage}
                      >
                        {CommonConstants.show_rows.map(
                          (value) => (
                            <option
                              value={
                                value
                              }
                            >
                              {value}
                            </option>
                          )
                        )}
                      </select>
                      entries
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 full-right">
                    <label className="textlabelte">
                      Search:{" "}
                      <input
                        type="search"
                        id="search"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        className="form-control form-control-sm ml-2"
                        placeholder=""
                        aria-controls="tbl_meeting"
                      />
                    </label>
                  </div>
                </div>
                <div className="table-bordered">
                  <TableContainer
                    component={Paper}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            onClick={() => {
                              handleSort(
                                "Status"
                              );
                            }}
                          >
                            Status
                            {/* </a> */}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              handleSort(
                                "FirstName"
                              );
                            }}
                          >
                            FirstName
                            {/* </a> */}
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "LastName"
                                );
                              }}
                            >
                              LastName
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "Email"
                                );
                              }}
                            >
                              Email
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "MeetingDate"
                                );
                              }}
                            >
                              Meeting Date
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "Meetingstatus"
                                );
                              }}
                            >
                              Meeting Status
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "MeetingSource"
                                );
                              }}
                            >
                              Meeting Source
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "MeetingOwner"
                                );
                              }}
                            >
                              Meeting Owner
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                handleSort(
                                  "MeetingTimeZone"
                                );
                              }}
                            >
                              Time Zone
                            </a>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentItems?.length ===
                          0 ? (
                          <p className="text-center">
                            No data
                            available in
                            table
                          </p>
                        ) : (
                          currentItems?.map(
                            (row) => (
                              <TableRow>
                                <TableCell>
                                  {
                                    row.Status =="Skiped"?(<span class="btnskip">{row.Status}</span>):row.Status =="Duplicate"?(<span class="btnskipmeeting">{row.Status}</span>):(<span class="btnno">{row.Status}</span>)
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.FirstName
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.LastName
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.Email
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.MeetingDate
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.Meetingstatus
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.MeetingSource
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.MeetingOwner
                                  }
                                </TableCell>
                                <TableCell>
                                  {
                                    row.MeetingTimeZone
                                  }
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </TableBody>

                      <div className="row"></div>
                    </Table>
                  </TableContainer>
                </div>
                {Sflag ? (
                  <div className="row">
                    <Pagination
                      component="div"
                      count={CountPage}
                      page={CSVEPage}
                      onChange={
                        handlePagination
                      }
                      showFirstButton
                      showLastButton
                    />
                    <div className="col dataTables_info">
                      <p>
                        Showing{" "}
                        {Rlen == 0
                          ? 0
                          : (CSVEPage -
                            1) *
                          CSVERowsPerPage +
                          1}{" "}
                        to{" "}
                        {CSVEPage *
                          CSVERowsPerPage >
                          Rlen
                          ? Rlen
                          : CSVEPage *
                          CSVERowsPerPage}{" "}
                        of {Rlen} entries
                        (filtered from{" "}
                        {Flen} total
                        entries)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col dataTables_info">
                      <p>
                        Showing{" "}
                        {Rlen == 0
                          ? 0
                          : (CSVEPage -
                            1) *
                          CSVERowsPerPage +
                          1}{" "}
                        to{" "}
                        {CSVEPage *
                          CSVERowsPerPage >
                          Rlen
                          ? Rlen
                          : CSVEPage *
                          CSVERowsPerPage}{" "}
                        of {Rlen} entries
                      </p>
                    </div>
                    <div className="col pageright">
                      <Pagination
                        component="div"
                        count={CountPage}
                        page={CSVEPage}
                        onChange={
                          handlePagination
                        }
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                )}
              </div></>):(<></>)
            }


            {/* Csv Error Code end */}
            <div className="row">
              <div className="col">
                <div className="bg-white">
                  <CollapsibleTable
                    FilterParameters={FilterParameters}
                    IsApply={FilterParameters?.length === 0 ? false : true}
                    updateFromChild={updateFromChild}
                    UpdateYourList={UpdateImportList}
                    ResetFilterMethod={ResetFilter}
                  />
                </div>
              </div>
            </div>
          </TabPanel> 

          <TabPanel value={value} className="tagpaddnone px-in" index={1}>
            <div className="tab">
              <InsightsTable />
            </div>
          </TabPanel>

          <TabPanel value={value} className="tagpaddnone px-in" index={2}>
            <div className="row">
              <div className="col">
              <div className="bg-white px-3 pb-3">
                <Pipeline />
              </div>
              </div>
            </div> 
          </TabPanel>

          <TabPanel value={value} className="tagpaddnone px-in" index={3}>
            <div className="row">
              <div className="col">
              <div className="bg-white px-3 pb-3">
                <MeetingSource />
              </div>
            </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}